import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";

class Overtime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col">
                  <h4 className="page-title">Overtime</h4>
                </div>
                {/* <div className="col-12 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_overtime"><i className="fa fa-plus"></i> Add Overtime</a>
						</div> */}
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Overtime Statistics -->*/}
              {/* <div className="row">
						<div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
							<div className="stats-info">
								<h6>Overtime Employee</h6>
								<h4>12 <span>this month</span></h4>
							</div>
						</div>
						<div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
							<div className="stats-info">
								<h6>Overtime Hours</h6>
								<h4>118 <span>this month</span></h4>
							</div>
						</div>
						<div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
							<div className="stats-info">
								<h6>Pending Request</h6>
								<h4>23</h4>
							</div>
						</div>
						<div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
							<div className="stats-info">
								<h6>Rejected</h6>
								<h4>5</h4>
							</div>
						</div>
					</div> */}
              {/*<!-- /Overtime Statistics -->*/}

              {/* <div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table mb-0 datatable">
									<thead>
										<tr>
											<th>#</th>
											<th>Name</th>
											<th>OT Date</th>
											<th className="text-center">OT Hours</th>
											<th>OT Type</th>
											<th>Description</th>
											<th className="text-center">Status</th>
											<th>Approved by</th>
											<th className="text-right">Actions</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>
												<h2 className="table-avatar blue-link">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-02.jpg" /></a>
													<a href="profile.html">John Doe</a>
												</h2>
											</td>
											<td>8 Mar 2019</td>
											<td className="text-center">2</td>
											<td>Normal day OT 1.5x</td>
											<td>Lorem ipsum dollar</td>
											<td className="text-center">
												<div className="action-label">
													<a className="btn btn-white btn-sm btn-rounded" href="javascript:void(0);">
														<i className="fa fa-dot-circle-o text-purple"></i> New
													</a>
												</div>
											</td>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar avatar-xs"><img src="assets/img/profiles/avatar-09.jpg" alt="" /></a>
													<a href="#">Richard Miles</a>
												</h2>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_overtime"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_overtime"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div> */}
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Overtime Modal -->*/}
            <div
              id="add_overtime"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Overtime</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>
                          Select Employee <span className="text-danger">*</span>
                        </label>
                        <select className="select">
                          <option>-</option>
                          <option>John Doe</option>
                          <option>Richard Miles</option>
                          <option>John Smith</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Overtime Date <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control datetimepicker"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Overtime Hours <span className="text-danger">*</span>
                        </label>
                        <input className="form-control" type="text" />
                      </div>
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <textarea rows="4" className="form-control"></textarea>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add Overtime Modal -->*/}

            {/*<!-- Edit Overtime Modal -->*/}
            <div
              id="edit_overtime"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Overtime</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>
                          Select Employee <span className="text-danger">*</span>
                        </label>
                        <select className="select">
                          <option>-</option>
                          <option>John Doe</option>
                          <option>Richard Miles</option>
                          <option>John Smith</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Overtime Date <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control datetimepicker"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Overtime Hours <span className="text-danger">*</span>
                        </label>
                        <input className="form-control" type="text" />
                      </div>
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <textarea rows="4" className="form-control"></textarea>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit Overtime Modal -->*/}

            {/*<!-- Delete Overtime Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_overtime"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Overtime</h3>
                      <p>Are you sure want to Cancel this?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Overtime Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Overtime));
