import React from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";

class Employee extends React.Component {
  render() {
    const { _id, name, profile, displayImage, gender, userId } = this.props;
    const href = `${URLS.profile}/${_id}`;

    const profileImage = displayImage
      ? URLS.backendStatic + "/" + displayImage
      : "/assets/img/profiles/avatar-03.jpg";

    return (
      <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
        <div className="profile-widget">
          <div className="profile-img">
            <Link to={href} className="avatar">
              <img
                src={profileImage}
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
            </Link>
          </div>
          <div className="dropdown profile-action">
            <a
              href="#"
              className="action-icon dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="material-icons">more_vert</i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#edit_employee"
                onClick={() => this.props.onEdit(_id, userId)}
              >
                <i className="fa fa-pencil m-r-5"></i> Edit
              </a>
              <a
                className="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#delete_employee"
                onClick={this.props.onDelete.bind(this, _id)}
              >
                <i className="fa fa-trash-o m-r-5"></i> Delete
              </a>
            </div>
          </div>
          <h4 className="user-name m-t-10 mb-0 text-ellipsis">
            <Link to={href}>
              {(name && name.first) +
                " " +
                (name && name.middle) +
                " " +
                (name && name.last)}
            </Link>
          </h4>
          <div className="small text-muted">{profile}</div>
        </div>
      </div>
    );
  }
}

export default Employee;
