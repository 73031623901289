import { createStore, combineReducers } from "redux";
import ApiReducer from "./components/api/ApiReducer.js";
import DeductionReducer from "./components/deduction-settings/deductionReducer.js";
import SettingsReducer from "./components/settings/settingsReducer.js";

const store = createStore(
  combineReducers({
    api: ApiReducer,
    deductionSettings: DeductionReducer,
    settingsReducer: SettingsReducer,
  })
);

export default store;
