const DeductionReducer = (
  state = {
    deductions: [],
  },
  action
) => {
  switch (action.type) {
    case "ADD_DEDUCTIONS":
      return { deductions: action.payload };

    case "DELETE_DEDUCTION":
      const slug = action.payload;
      let deductions = state.deductions;
      deductions = deductions.filter((deduction) => deduction.slug !== slug);
      return {
        ...state,
        deductions,
      };

    case "ADD_DEDUCTION":
      return {
        ...state,
        deductions: [...state.deductions, action.payload],
      };

    default:
      return state;
  }
};

export default DeductionReducer;
