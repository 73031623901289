import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import UserSidebar from "../user-dashboard/user-sidebar/UserSidebar";
import Project from "../projects/Project";
import AllTypeTasks from "../tasks/AllTypeTasksClient";
import { URLS } from "../urls";
import HSBar from "react-horizontal-stacked-bar-chart";
import moment from "moment";
import CompletionTaskChart from "./CompletionTaskChart";
import PerfomanceTaskChart from "./PerfomanceTaskChart";

// const completionTaskData = [
//     {date : '21 Mar', tasks : 150 },
//     {date : '22 Mar', tasks : 50 },
//     {date : '23 Mar', tasks : 450 },
//     {date : '24 Mar', tasks : 300 },
//     {date : '25 Mar', tasks : 250 },
//     {date : '26 Mar', tasks : 350 },
//     {date : '27 Mar', tasks : 100 }
// ];
// const perfomanceTaskData = {
//     Assigned : [{type : 'Assigned', tasks : 100 }],
//     Completed : [{type : 'Completed', tasks : 75 }],
//     Active : [{type : 'Active', tasks :  60}],
// }

class TeamView extends React.Component {
  constructor(props) {
    super(props);

    const cookies = new Cookies();
    const isAdmin = cookies.get("isAdmin");
    const isSuperAdmin = cookies.get("isSuperAdmin");

    this.state = {
      team: {},
      editTeam: "",
      projects: {},
      tasks: {},
      completedTasks: {},
      completionRate: {},
      activeTasks: {},
      overDueTasks: {},
      underReviewTasks: {},
      perfomanceTaskData: {
        Assigned: [{ type: "Assigned", tasks: 0 }],
        Completed: [{ type: "Completed", tasks: 0 }],
        Active: [{ type: "Active", tasks: 0 }],
      },
      completionTaskData: [],
      teamProjects: [],
      projectTasks: [],
      isAdmin: isAdmin,
      isSuperAdmin: isSuperAdmin,
    };
  }

  componentWillMount() {
    const teamId = window.location.pathname.split("/")[3];
    const token = "dummy token";
    fetch(`${URLS.backendTeams}/get/${teamId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then(async (result) => {
        const team = result.team;
        if (team) {
          await this.setState({ team: team });
          await this.fetchProjectsByTeamId(teamId);
          await this.fetchTasksByTeamId(teamId);
        }
      });

    fetch(`${URLS.backendTasks}/getDateWiseCompletedTasksByTeamId/${teamId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const tasks = result.tasks;
        if (tasks && tasks.length) {
          this.setState({ completionTaskData: tasks });
        }
      });
  }

  fetchProjectsByTeamId = (id) => {
    const token = "dummy token";
    fetch(`${URLS.backendProjects}/getProjectsByTeamId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const {
          projects,
          team,
          tasks,
          completedTasks,
          activeTasks,
          overDueTasks,
          underReviewTasks,
        } = this.state;
        const pl = result.projects.length;
        const teamProjects = result.projects;
        projects["open"] = pl;
        projects["complete"] = 0;
        team.leaders.forEach((leader) => {
          projects[leader.id] = pl;
          tasks[leader.id] = 0;
          completedTasks[leader.id] = 0;
          underReviewTasks[leader.id] = 0;
          activeTasks[leader.id] = 0;
          overDueTasks[leader.id] = 0;
        });
        team.members.forEach((member) => {
          projects[member.id] = pl;
          tasks[member.id] = 0;
          completedTasks[member.id] = 0;
          underReviewTasks[member.id] = 0;
          activeTasks[member.id] = 0;
          overDueTasks[member.id] = 0;
        });
        this.setState({
          projects,
          tasks,
          completedTasks,
          activeTasks,
          overDueTasks,
          underReviewTasks,
          teamProjects,
        });
      });
  };
  fetchTasksByTeamId = (id) => {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/getTasksByTeamId/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("hdgdggd");
        const allTasks = result.tasks;
        const projectTasks = result.tasks || [];
        const {
          tasks,
          completedTasks,
          activeTasks,
          overDueTasks,
          underReviewTasks,
        } = this.state;
        tasks["open"] = 0;
        tasks["complete"] = 0;
        tasks["total"] = 0;
        tasks["assigned"] = 0;
        if (allTasks) {
          allTasks.forEach((task) => {
            tasks["total"] += 1;
            let assignedUser = task.assignedTo;
            if (assignedUser && assignedUser !== "") {
              tasks["assigned"] += 1;
              tasks[assignedUser] += 1;
              if (task.completeDate) {
                if (task.status === "UNDER_REVIEW") {
                  underReviewTasks[assignedUser] += 1;
                  tasks["open"] += 1;
                } else {
                  completedTasks[assignedUser] += 1;
                  tasks["complete"] += 1;
                }
              } else {
                tasks["open"] += 1;
                const diff = moment(new Date(), "DD/MM/YYYY").diff(
                  moment(task.dueDate, "DD/MM/YYYY"),
                  "days"
                );
                if (diff > 0) overDueTasks[assignedUser] += 1;

                activeTasks[assignedUser] += 1;
              }
            }
          });
        }
        if (tasks["total"]) {
          const perfomanceTaskData = {
            Assigned: [
              {
                type: "Assigned",
                tasks: parseFloat(
                  ((tasks["assigned"] * 100) / tasks["total"]).toFixed(2)
                ),
              },
            ],
            Completed: [
              {
                type: "Completed",
                tasks: parseFloat(
                  ((tasks["complete"] * 100) / tasks["total"]).toFixed(2)
                ),
              },
            ],
            Active: [
              {
                type: "Active",
                tasks: parseFloat(
                  ((tasks["open"] * 100) / tasks["total"]).toFixed(2)
                ),
              },
            ],
          };
          this.setState({ perfomanceTaskData });
        }

        this.setState({
          tasks,
          completedTasks,
          activeTasks,
          overDueTasks,
          underReviewTasks,
          projectTasks,
        });

        this.forceUpdate();
      });
  };

  fetchTasksByEmployeeId = (id) => {
    const token = "dummy token";
    fetch(`${URLS.backendTasks}/tasks/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        let {
          tasks,
          completedTasks,
          completionRate,
          overDueTasks,
          activeTasks,
        } = this.state;
        const taskLength = result.tasks.length;
        tasks[id] = taskLength;
        const completed = result.tasks.filter((task) => {
          return task.status === "COMPLETE";
        });
        const completedLength = completed.length;
        let delay = 0;
        if (completedLength) {
          for (let i = 0; i < completedLength; i++) {
            let item = completed[i];
            const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
              moment(item.dueDate, "DD/MM/YYYY"),
              "days"
            );
            if (diff > 0) delay += diff;
          }
        }
        let overDueTasksCount = 0;
        let activeTasksCount = 0;
        result.tasks.forEach((item) => {
          if (item.completeDate) {
            const diff = moment(item.completeDate, "DD/MM/YYYY").diff(
              moment(item.dueDate, "DD/MM/YYYY"),
              "days"
            );
            if (diff > 0) overDueTasksCount += 1;
          } else {
            activeTasksCount += 1;
            const diff = moment(new Date(), "DD/MM/YYYY").diff(
              moment(item.dueDate, "DD/MM/YYYY"),
              "days"
            );
            if (diff > 0) overDueTasksCount += 1;
          }
        });
        activeTasks[id] = activeTasksCount;
        overDueTasks[id] = overDueTasksCount;
        completedTasks[id] = completedLength;
        completionRate[id] = completedLength
          ? (completed.length / (completed.length + delay * 0.1)) * 100
          : 0;
        this.setState({
          tasks,
          completedTasks,
          completionRate,
          overDueTasks,
        });
      });
  };

  dateInFormat(date) {
    if (date) date = date.split("T")[0];
    return date;
  }

  render() {
    const {
      team,
      projects,
      tasks,
      completedTasks,
      overDueTasks,
      activeTasks,
      underReviewTasks,
      perfomanceTaskData,
      completionTaskData,
      teamProjects,
      projectTasks,
      isAdmin,
      isSuperAdmin,
    } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          {isAdmin || isSuperAdmin ? <Sidebar /> : <UserSidebar />}
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-7 col-5">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={`${URLS.teams}`}>Teams</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Team Profile</BreadcrumbItem>
                  </Breadcrumb>
                  {/* <h4 className="page-title">{team.name}</h4>
                                        <h4 className="page-title"></h4> */}
                </div>
                {/* <div className="col-sm-5 col-7 text-right m-b-30">
									<a href='Javascript:void(0);' className="btn add-btn" data-toggle="modal" data-target="#edit_team"><i className="fa fa-plus"></i> Edit Team</a>
								</div> */}
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-md-5">
                  <div className="profile-info-left">
                    <h3 className="user-name m-t-0 mb-0">{team.name}</h3>
                    <small
                      className="block text-ellipsis"
                      style={{ marginBottom: "5px" }}
                    >
                      <span
                        className="text-xl"
                        style={{ verticalAlign: "middle" }}
                      >
                        <i
                          style={{ fontSize: "24px" }}
                          className="la la-group"
                        />{" "}
                      </span>{" "}
                      <span class="text-xs">{team.totalSize}</span>{" "}
                      <span className="text-muted"> Members </span>
                      <span
                        className="text-xs"
                        style={{ verticalAlign: "middle" }}
                      >
                        <i
                          style={{ fontSize: "24px" }}
                          className="la la-calendar"
                        ></i>{" "}
                      </span>{" "}
                      <span className="text-muted">
                        {" "}
                        Created: {this.dateInFormat(team.createdAt)}
                      </span>
                    </small>
                    <small
                      className="block text-ellipsis"
                      style={{ marginBottom: "5px" }}
                    >
                      <span
                        className="text-xl"
                        style={{ verticalAlign: "middle" }}
                      >
                        <i
                          style={{ fontSize: "24px" }}
                          className="la la-rocket"
                        />{" "}
                      </span>
                      <span class="text-xs">{projects["open"]}</span>{" "}
                      <span class="text-muted">Open Projects, </span>
                      <span class="text-xs">{projects["complete"]}</span>{" "}
                      <span class="text-muted">Completed Projects</span>
                    </small>
                    <small
                      className="block text-ellipsis"
                      style={{ marginBottom: "5px" }}
                    >
                      <span
                        className="text-xl"
                        style={{ verticalAlign: "middle" }}
                      >
                        <i
                          style={{ fontSize: "24px" }}
                          className="la la-clock-o"
                        ></i>{" "}
                      </span>
                      <span class="text-xs">{tasks["open"]}</span>{" "}
                      <span class="text-muted">Open Tasks, </span>
                      <span class="text-xs">{tasks["complete"]}</span>{" "}
                      <span class="text-muted">Completed Tasks</span>
                    </small>
                    <div class="text-muted">{team.description}</div>
                    <br />
                    <div className="project-members m-b-15">
                      <div>Team Leader:</div>
                      <ul style={{ listStyle: "none", padding: "0 20px" }}>
                        {team.leaders &&
                          team.leaders.map((leader) => {
                            const id = leader.id;
                            return (
                              <div
                                style={{ display: "flex", padding: "4px 0px" }}
                              >
                                <div
                                  className="title"
                                  style={{ flex: "1", textAlign: "center" }}
                                >
                                  <a
                                    href="Javascript:void(0);"
                                    title={leader.name}
                                    class="avatar"
                                  >
                                    <img
                                      src={
                                        leader.image
                                          ? URLS.backendStatic +
                                            "/" +
                                            leader.image
                                          : "/assets/img/profiles/avatar-03.jpg"
                                      }
                                      alt={"picokdone"}
                                    />
                                  </a>
                                  <div>{leader.name}</div>
                                </div>
                                <div
                                  className="text"
                                  style={{ flex: "2", textAlign: "center" }}
                                >
                                  <HSBar
                                    height={15}
                                    data={[
                                      {
                                        name: "Completed",
                                        value: completedTasks[id],
                                        color: "#9c27b0",
                                      },
                                      {
                                        name: "Under Review",
                                        value: underReviewTasks[id],
                                        color: "#8884d8",
                                      },
                                      {
                                        name: "Active",
                                        value: activeTasks[id],
                                        color: "#00b386",
                                      },
                                      {
                                        name: "Overdue",
                                        value: overDueTasks[id],
                                        color: "#f23602",
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-md-6">
                      Completion Tasks
                      <CompletionTaskChart data={completionTaskData} />
                    </div>
                    <div className="col-md-6">
                      Tasks Perfomance
                      <PerfomanceTaskChart data={perfomanceTaskData} />
                      <div>
                        <div style={{ display: "inline-block" }}>
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              borderRadius: "10px",
                              backgroundColor: "#8884d8",
                              display: "inline-block",
                              margin: "0 8px",
                            }}
                          ></div>
                          <span>
                            Assigned{" "}
                            {tasks["total"]
                              ? (
                                  (tasks["assigned"] * 100) /
                                  tasks["total"]
                                ).toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div style={{ display: "inline-block" }}>
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              borderRadius: "10px",
                              backgroundColor: "#00b386",
                              display: "inline-block",
                              margin: "0 8px",
                            }}
                          ></div>
                          <span>
                            Active{" "}
                            {tasks["total"]
                              ? (
                                  (tasks["open"] * 100) /
                                  tasks["total"]
                                ).toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                        <div style={{ display: "inline-block" }}>
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              borderRadius: "10px",
                              backgroundColor: "#9c27b0",
                              display: "inline-block",
                              margin: "0 8px",
                            }}
                          ></div>
                          <span id="name-completed">
                            Completed{" "}
                            {tasks["total"]
                              ? (
                                  (tasks["complete"] * 100) /
                                  tasks["total"]
                                ).toFixed(2)
                              : 0}
                            %
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box tab-box">
                <div className="row user-tabs">
                  <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item">
                        <a
                          href="#members_board"
                          data-toggle="tab"
                          className="nav-link active"
                        >
                          Members Board
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#team_projects"
                          data-toggle="tab"
                          className="nav-link"
                        >
                          Projects
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#team_tasks"
                          data-toggle="tab"
                          className="nav-link"
                        >
                          Tasks
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div
                  id="members_board"
                  className="pro-overview tab-pane fade show active"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <table className="table table-striped custom-table mb-0 datatable">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Projects</th>
                              <th>Tasks</th>
                              <th>Completed Tasks</th>
                              <th>Progress</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                Tasks
                                <div style={{ display: "inline-block" }}>
                                  <div
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      borderRadius: "10px",
                                      backgroundColor: "#9c27b0",
                                      display: "inline-block",
                                      margin: "0 8px",
                                    }}
                                  ></div>
                                  <span id="name-completed">Completed</span>
                                </div>
                                <div style={{ display: "inline-block" }}>
                                  <div
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      borderRadius: "10px",
                                      backgroundColor: "#8884d8",
                                      display: "inline-block",
                                      margin: "0 8px",
                                    }}
                                  ></div>
                                  <span>Under Review</span>
                                </div>
                                <div style={{ display: "inline-block" }}>
                                  <div
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      borderRadius: "10px",
                                      backgroundColor: "#00b386",
                                      display: "inline-block",
                                      margin: "0 8px",
                                    }}
                                  ></div>
                                  <span>Active</span>
                                </div>
                                <div style={{ display: "inline-block" }}>
                                  <div
                                    style={{
                                      height: "10px",
                                      width: "10px",
                                      borderRadius: "10px",
                                      backgroundColor: "#f23602",
                                      display: "inline-block",
                                      margin: "0 8px",
                                    }}
                                  ></div>
                                  <span>Overdue</span>
                                </div>
                              </td>
                            </tr>
                            {team.members
                              ? team.members.map((member, index) => {
                                  const id = member.id;
                                  const image = member.image;
                                  // const progress = tasks[id] > 0 ? (completedTasks[id]*100 / tasks[id]).toFixed(2) : 0
                                  // const rate = completionRate[id] ? completionRate[id].toFixed(2) : 0;
                                  return (
                                    <tr>
                                      <td>
                                        <Link to={`${URLS.profile}/${id}`}>
                                          <span className="avatar">
                                            <img
                                              alt=""
                                              src={
                                                image
                                                  ? URLS.backendStatic +
                                                    "/" +
                                                    image
                                                  : "assets/img/profiles/avatar-09.jpg"
                                              }
                                            />
                                          </span>
                                          {member.name}{" "}
                                        </Link>
                                      </td>
                                      <td>{projects[id]}</td>
                                      <td>{tasks[id]}</td>
                                      <td>{completedTasks[id]}</td>
                                      <td>
                                        <HSBar
                                          height={15}
                                          fontColor="rgb(50,20,100)"
                                          data={[
                                            {
                                              name: "Completed",
                                              value: completedTasks[id],
                                              color: "#9c27b0",
                                            },
                                            {
                                              name: "Under Review",
                                              value: underReviewTasks[id],
                                              color: "#8884d8",
                                            },
                                            {
                                              name: "Active",
                                              value: activeTasks[id],
                                              color: "#00b386",
                                            },
                                            {
                                              name: "Overdue",
                                              value: overDueTasks[id],
                                              color: "#f23602",
                                            },
                                          ]}
                                        />
                                        {/* <div className="widget-subbody" >
                                                                                <div className="widget-subbody-header">
                                                                                    Progress <span class="text-success float-right">{progress.toString() + '%'}</span>
                                                                                </div>
                                                                                <div class="progress progress-xs mb-0">
                                                                                    <div class="progress-bar bg-success" role="progressbar" data-toggle="tooltip" title={progress.toString() + '%'} style={{ width: progress + "%" }}></div>
                                                                                </div>
                                                                            </div> */}
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="team_projects" className="pro-overview tab-pane fade">
                  <div className="row staff-grid-row">
                    {teamProjects.length ? (
                      teamProjects.map((project) => {
                        let projectTeam = [];
                        let projectLeader = [];
                        if (project.team && project.team.length) {
                          projectTeam = project.team.map((employee) => {
                            let name = employee.personalInfo.name.first;
                            name += employee.personalInfo.name.middle
                              ? " " + employee.personalInfo.name.middle
                              : "";
                            name += employee.personalInfo.name.last
                              ? " " + employee.personalInfo.name.last
                              : "";
                            const id = employee._id;
                            const image = employee.emergencyInfo.image;
                            return { name: name, id: id, image: image };
                          });
                        }
                        if (
                          project.projectLeader &&
                          project.projectLeader.length
                        ) {
                          projectLeader = project.projectLeader.map(
                            (employee) => {
                              let name = employee.personalInfo.name.first;
                              name += employee.personalInfo.name.middle
                                ? " " + employee.personalInfo.name.middle
                                : "";
                              name += employee.personalInfo.name.last
                                ? " " + employee.personalInfo.name.last
                                : "";
                              const id = employee._id;
                              const image = employee.emergencyInfo.image;
                              return { name: name, id: id, image: image };
                            }
                          );
                        }
                        return (
                          <Project
                            key={project._id}
                            project={project}
                            projectTeamList={projectTeam}
                            projectLeaderList={projectLeader}
                            canShowControls={false}
                          />
                        );
                      })
                    ) : (
                      <div
                        style={{ height: "150px" }}
                        className="col-md-12 d-flex justify-content-center align-items-center"
                      >
                        <h5>No Project Found</h5>
                      </div>
                    )}
                  </div>
                </div>
                <div id="team_tasks" className="pro-overview tab-pane fade">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="project-task">
                        <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              href="#all_tasks"
                              data-toggle="tab"
                              aria-expanded="true"
                            >
                              All Tasks
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="#pending_tasks"
                              data-toggle="tab"
                              aria-expanded="false"
                            >
                              Pending Tasks
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="#under_reviewed_tasks"
                              data-toggle="tab"
                              aria-expanded="false"
                            >
                              Under Review Tasks
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              href="#completed_tasks"
                              data-toggle="tab"
                              aria-expanded="false"
                            >
                              Completed Tasks
                            </a>
                          </li>
                        </ul>
                        <AllTypeTasks projectTasks={projectTasks} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TeamView));
