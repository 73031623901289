import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { URLS } from "../urls";
import { Row } from "reactstrap";
import Loader from "../Loader";

function SalarySummary() {
  const date = new Date();
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });

  const [isLoading, setIsLoading] = useState(false);

  const [payroll, setPayroll] = useState([]);

  useEffect(() => {
    getPayroll();

    return () => {};
  }, [payrollDate]);

  async function getPayroll() {
    try {
      setIsLoading(true);
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;
      if (payroll.length) {
        setPayroll(payroll);
      } else {
        setPayroll([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Salary Summary</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="mt-3 col-sm-12 col-12 p-0">
              <div className="d-flex col-md-6 my-4">
                <div className="form-inline">
                  <label>Month</label>
                  <select
                    className="form-control mx-2"
                    value={payrollDate.payrollMonth}
                    onChange={(e) => {
                      setPayrollDate({
                        payrollMonth: e.target.value,
                        payrollYear: payrollDate.payrollYear,
                      });
                    }}
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <div className="form-inline ml-3">
                  <label>Year</label>
                  <input
                    type="number"
                    style={{ width: "100px" }}
                    className="form-control mx-2"
                    value={payrollDate.payrollYear}
                    onChange={(e) => {
                      setPayrollDate({
                        payrollMonth: payrollDate.payrollMonth,
                        payrollYear: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <Row className="m-0">
                {isLoading && <Loader />}
                {payroll.length ? (
                  <div className="col-md-12 w-100">
                    <div className="table-responsive">
                      <table className="table table-striped custom-table mb-0 datatable">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Amount</th>
                            <th>Debit Account No</th>
                            <th>Debit Bank Code</th>
                            <th>Credit Account No</th>
                            <th>Credit Bank Code</th>
                            <th>Beneficiary</th>
                            <th>Payment Date</th>
                            <th>Narration</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payroll.map((employee, index) => {
                            return (
                              <tr Key={index}>
                                <td>{index + 1}</td>
                                <td>{employee.netPay}</td>
                                <td>{employee.debitAccountNo}</td>
                                <td>{employee.debitBankCode}</td>
                                <td>{employee.creditAccountNo}</td>
                                <td>{employee.creditBankCode}</td>
                                <td>
                                  {employee.user?.personalInfo?.name.first +
                                    " " +
                                    employee.user?.personalInfo?.name.last}
                                </td>
                                <td>
                                  {new Date(employee.createdAt).toDateString()}
                                </td>
                                <td>
                                  {employee.payrollMonth +
                                    " " +
                                    employee.payrollYear}{" "}
                                  Salary
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <div
                    className="w-100 d-flex justify-content-center align-items-center bg-white"
                    style={{ height: "250px" }}
                  >
                    <p>No Payroll processed</p>
                  </div>
                )}
              </Row>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Page Wrapper -->*/}
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    // deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SalarySummary));
