import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-4 col-4">
                  <h4 className="page-title">Users</h4>
                </div>
                {/* <div className="col-sm-8 col-8 text-right m-b-30">
							<a href="#" className="btn add-btn" data-toggle="modal" data-target="#add_user"><i className="fa fa-plus"></i> Add User</a>
						</div> */}
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Search Filter -->*/}
              {/* <div className="row filter-row">
						<div className="col-sm-6 col-md-3">  
							<div className="form-group form-focus">
								<input type="text" className="form-control floating" />
								<label className="focus-label">Name</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3"> 
							<div className="form-group form-focus select-focus">
								<select className="select floating"> 
									<option>Select Company</option>
									<option>Global Technologies</option>
									<option>Delta Infotech</option>
								</select>
								<label className="focus-label">Company</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3"> 
							<div className="form-group form-focus select-focus">
								<select className="select floating"> 
									<option>Select Roll</option>
									<option>Web Developer</option>
									<option>Web Designer</option>
									<option>Android Developer</option>
									<option>Ios Developer</option>
								</select>
								<label className="focus-label">Role</label>
							</div>
						</div>
						<div className="col-sm-6 col-md-3">  
							<a href="#" className="btn btn-success btn-block"> Search </a>  
						</div>     
                    </div> */}
              {/*<!-- /Search Filter -->*/}

              {/* <div className="row">
						<div className="col-md-12">
							<div className="table-responsive">
								<table className="table table-striped custom-table datatable">
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Company</th>
											<th>Created Date</th>
											<th>Role</th>
											<th className="text-right">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img src="assets/img/profiles/avatar-21.jpg" alt="" /></a>
													<a href="profile.html">Daniel Porter <span>Admin</span></a>
												</h2>
											</td>
											<td>danielporter@example.com</td>
											<td>Dreamguy's Technologies</td>
											<td>1 Jan 2013</td>
											<td>
												<span className="badge badge-danger-border">Admin</span>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-02.jpg" /></a>
													<a href="profile.html">John Doe <span>Web Designer</span></a>
												</h2>
											</td>
											<td>johndoe@example.com</td>
											<td>Dreamguy's Technologies</td>
											<td>1 Jan 2013</td>
											<td>
											<span className="badge badge-success-border">Employee</span>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-09.jpg" /></a>
													<a href="profile.html">Richard Miles <span>Admin</span></a>
												</h2>
											</td>
											<td>richardmiles@example.com</td>
											<td>Dreamguy's Technologies</td>
											<td>1 Jan 2013</td>
											<td>
												<span className="badge badge-success-border">Employee</span>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-10.jpg" /></a>
													<a href="profile.html">John Smith <span>Android Developer</span></a>
												</h2>
											</td>
											<td>johnsmith@example.com</td>
											<td>Dreamguy's Technologies</td>
											<td>1 Jan 2013</td>
											<td>
												<span className="badge badge-success-border">Employee</span>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-05.jpg" /></a>
													<a href="profile.html">Mike Litorus <span>IOS Developer</span></a>
												</h2>
											</td>
											<td>mikelitorus@example.com</td>
											<td>Dreamguy's Technologies</td>
											<td>1 Jan 2013</td>
											<td>
												<span className="badge badge-success-border">Employee</span>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img alt="" src="assets/img/profiles/avatar-11.jpg" /></a>
													<a href="profile.html">Wilmer Deluna <span>Team Leader</span></a>
												</h2>
											</td>
											<td>wilmerdeluna@example.com</td>
											<td>Dreamguy's Technologies</td>
											<td>1 Jan 2013</td>
											<td>
												<span className="badge badge-success-border">Employee</span>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<h2 className="table-avatar">
													<a href="profile.html" className="avatar"><img src="assets/img/profiles/avatar-19.jpg" alt="" /></a>
													<a href="profile.html">Barry Cuda <span>Global Technologies</span></a>
												</h2>
											</td>
											<td>barrycuda@example.com</td>
											<td>Global Technologies</td>
											<td>1 Jan 2013</td>
											<td>
												<span className="badge badge-info-border">Client</span>
											</td>
											<td className="text-right">
												<div className="dropdown dropdown-action">
													<a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
													<div className="dropdown-menu dropdown-menu-right">
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
														<a className="dropdown-item" href="#" data-toggle="modal" data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>*/}
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add User Modal -->*/}
            <div
              id="add_user"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add User</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Username <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Email <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="email" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Password</label>
                            <input className="form-control" type="password" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <input className="form-control" type="password" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Phone </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Role</label>
                            <select className="select">
                              <option>Admin</option>
                              <option>Client</option>
                              <option>Employee</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Company</label>
                            <select className="select">
                              <option>Global Technologies</option>
                              <option>Delta Infotech</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Employee ID <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control floating"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive m-t-15">
                        <table className="table table-striped custom-table">
                          <thead>
                            <tr>
                              <th>Module Permission</th>
                              <th className="text-center">Read</th>
                              <th className="text-center">Write</th>
                              <th className="text-center">Create</th>
                              <th className="text-center">Delete</th>
                              <th className="text-center">Import</th>
                              <th className="text-center">Export</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Employee</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                            <tr>
                              <td>Holidays</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                            <tr>
                              <td>Leaves</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                            <tr>
                              <td>Events</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add User Modal -->*/}

            {/*<!-- Edit User Modal -->*/}
            <div
              id="edit_user"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit User</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              value="John"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Last Name</label>
                            <input
                              className="form-control"
                              value="Doe"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Username <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              value="johndoe"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Email <span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              value="johndoe@example.com"
                              type="email"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Password</label>
                            <input className="form-control" type="password" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Confirm Password</label>
                            <input className="form-control" type="password" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Phone </label>
                            <input
                              className="form-control"
                              value="9876543210"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Role</label>
                            <select className="select">
                              <option>Admin</option>
                              <option>Client</option>
                              <option selected>Employee</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Company</label>
                            <select className="select">
                              <option>Global Technologies</option>
                              <option>Delta Infotech</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Employee ID <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              value="FT-0001"
                              className="form-control floating"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive m-t-15">
                        <table className="table table-striped custom-table">
                          <thead>
                            <tr>
                              <th>Module Permission</th>
                              <th className="text-center">Read</th>
                              <th className="text-center">Write</th>
                              <th className="text-center">Create</th>
                              <th className="text-center">Delete</th>
                              <th className="text-center">Import</th>
                              <th className="text-center">Export</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Employee</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                            <tr>
                              <td>Holidays</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                            <tr>
                              <td>Leaves</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                            <tr>
                              <td>Events</td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                              <td className="text-center">
                                <input checked="" type="checkbox" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit User Modal -->*/}

            {/*<!-- Delete User Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_user"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete User</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete User Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
