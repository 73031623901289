import React from 'react';
import { URLS } from '../urls';
import moment from 'moment';
import DatePicker from 'react-datetime';
import { Form, Col, Row, Image } from 'react-bootstrap';
export default class EditLeaveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonText : 'Submit',
            isWaiting : false,
            editLeave : {
                employee : {
                    id : null,
                    name : '',
                    image : '',
                    designation : ''
                },
                leaveId : '',
                name : '',
                from : moment(new Date()).format("DD/MM/YYYY"),
                to : moment(new Date()).format("DD/MM/YYYY"),
                days : 1,
                remaining : 0,
                reason : '',
                status : 'New',
                appliedDate : ''
            },
            employees : [],
            employeeAvailableLeaves : []
        };
    }
    componentWillReceiveProps(props){
        if(props.employees.length)
            this.setState({
                employees : props.employees
            })
        if(props.employeeAvailableLeaves){
            this.setState({
                employeeAvailableLeaves : props.employeeAvailableLeaves
            })
        }
        if(props.editLeave)
            this.setState({
                editLeave : props.editLeave
            })
    }
    updateLeaveEmployee = (e) =>{
        const{editLeave,employees,leaveBalances} = this.state;
        const id = e.target.value;
        editLeave.employee = employees.find(employee => employee.id === id)
        this.setState({
            editLeave,
            employeeAvailableLeaves : leaveBalances[id]
        })
    }
    updateLeaveName = (e) =>{
        const{editLeave,employeeAvailableLeaves} = this.state;
        editLeave.leaveId = e.target.value;
        const leaveType = employeeAvailableLeaves.find(l => l._id == editLeave.leaveId);
        editLeave.name = leaveType.name;
        this.setState({
            editLeave
        })
    }
    updateLeaveFrom = (event) => {
		const {editLeave} = this.state;
		editLeave.from= event.target ? event.target.value : moment(event).format("DD/MM/YYYY");
        const diff = moment(editLeave.to,"DD/MM/YYYY").diff(moment(editLeave.from,"DD/MM/YYYY"),'days')+1;
        editLeave.remaining +=  (editLeave.days - diff)
        editLeave.days = diff
        this.setState({
			editLeave
		})
    }
    updateLeaveTo = (event) => {
		const {editLeave} = this.state;
        editLeave.to= event.target ? event.target.value : moment(event).format("DD/MM/YYYY");
        const diff = moment(editLeave.to,"DD/MM/YYYY").diff(moment(editLeave.from,"DD/MM/YYYY"),'days')+1;
        editLeave.remaining +=  (editLeave.days - diff)
        editLeave.days = diff
        this.setState({
			editLeave
		})
	}
    updateLeaveDays = (e) =>{
        const{editLeave} = this.state;
        editLeave.days = e.target.value;
        this.setState({
            editLeave
        })
    }
    updateLeaveRemaining = (e) =>{
        const{editLeave} = this.state;
        editLeave.remaining = e.target.value;
        this.setState({
            editLeave
        })
    }
    updateLeaveReason = (e) =>{
        const{editLeave} = this.state;
        editLeave.reason = e.target.value;
        this.setState({
            editLeave
        })
    }
    
    submit = (e) => {
        e.preventDefault();
        this.setState({buttonText:'Submitting ...',isWaiting:true})
        const { editLeave } = this.state;
        console.log(editLeave);
        const token = 'dummy token'; 
        fetch(`${URLS.backendApplyLeaves}/update`, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Token ${token}`
            },
            body: JSON.stringify({applyLeave : editLeave}),
        }).then(res =>{
            if(res.ok){
                res.json().then((result) => {
                    console.log(result);
                    setTimeout(()=>{
                        window.$("#edit_leave").modal("toggle");
                        this.setState({ buttonText: 'Submit', isWaiting: false });
                        this.props.updateAppliedLeavesList(result.applyLeave);
                    }, 500);
                });
            }else{
                res.json().then((responseJson) => {
                    console.log(responseJson);
                    alert("Error occurred, Check the form again.");
                    this.setState({ buttonText: 'Submit', isWaiting: false });
                });
            }
        });
    };
    validationFrom = (currentDate) => {
        return currentDate.isAfter(moment(new Date()).add(-1,'days'));
        
    };
    validationTo = (currentDate) => {
        const{editLeave} = this.state;
        if(editLeave.from){
            return currentDate.isBefore(moment(editLeave.from, "DD/MM/YYYY").add(editLeave.days+editLeave.remaining,'days')) && currentDate.isAfter(moment(editLeave.from,"DD/MM/YYYY").add(-1,'days'));
        }
        else return currentDate.isAfter(moment(new Date()).add(-1,'days'));;
        
    };

	render() {
        const {editLeave,employees,employeeAvailableLeaves} = this.state;
        console.log("employeeAvailableLeaves",employeeAvailableLeaves)
        return (
            <div id="edit_leave" className="modal custom-modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Leave</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={this.submit}>
                                <div className="form-group">
                                    <label>Employee<span className="text-danger">*</span></label>
                                    <Form.Control as='select' value={editLeave.employee.id}
                                        onChange={this.updateLeaveEmployee} style={{height:'35px'}} disabled>
                                        <option value=''>Select Employee</option>
                                        {
                                            employees.length ? employees.map(employee => {
                                               return(
                                                    <option value={employee.id}>
                                                        {employee.name}
                                                    </option>
                                                )
                                            }) : ''
                                        }
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label>Leave Type <span className="text-danger">*</span></label>
                                    <Form.Control as='select' value={editLeave.leaveId}
                                        onChange={this.updateLeaveName} style={{height:'35px'}}>
                                        <option value=''>Select Type</option>
                                        {
                                            employeeAvailableLeaves ? employeeAvailableLeaves.map(leaveType =>{
                                                return(
                                                    <option value={leaveType._id}>{leaveType.name}</option>
                                                )
                                            }) : ''
                                        }
                                    </Form.Control>
                                </div>
                                <div className="form-group">
                                    <label>From <span className="text-danger">*</span></label>
                                    <div className="cal-icon">
                                        <DatePicker 
                                            value={editLeave.from} 
                                            closeOnSelect={true}
                                            onChange={this.updateLeaveFrom}
                                            isValidDate={this.validationFrom}
                                            required={true}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>To <span className="text-danger">*</span></label>
                                    <div className="cal-icon">
                                        <DatePicker 
                                            value={editLeave.to} 
                                            closeOnSelect={true}
                                            onChange={this.updateLeaveTo}
                                            isValidDate={this.validationTo}
                                            required={true}/>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Number of days <span className="text-danger">*</span></label>
                                    <input className="form-control" disabled type="number"  value={editLeave.days}/>
                                </div>
                                <div className="form-group">
                                    <label>Remaining Leaves <span className="text-danger">*</span></label>
                                    <input className="form-control" disabled type="number" value={editLeave.days + editLeave.remaining}/>
                                </div>
                                <div className="form-group">
                                    <label>Leave Reason <span className="text-danger">*</span></label>
                                    <textarea rows="4" className="form-control" value={editLeave.reason} onChange={this.updateLeaveReason}></textarea>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">{this.state.buttonText}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}