import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";

import classnames from "classnames";
import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import Loader from "../Loader";

function DeductionReports() {
  const date = new Date();
  const [activeTab, setActiveTab] = useState("1");
  const [deductions, setDeductions] = useState([]);
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });
  const [payroll, setPayroll] = useState([]);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPayroll();

    return () => {};
  }, [payrollDate]);

  async function getPayroll() {
    try {
      setIsLoading(true);
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;
      if (payroll.length) {
        setDeductions(payroll[0].deductions);
        setActiveTab(payroll[0].deductions[0].slug);
        setPayroll(payroll);
      } else {
        setPayroll([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Deduction Reports</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="p-3 col-sm-12 col-12 p-0">
              {isLoading && <Loader />}
              <div className="d-flex col-md-6 my-4">
                <div className="form-inline">
                  <label>Month</label>
                  <select
                    className="form-control mx-2"
                    value={payrollDate.payrollMonth}
                    onChange={(e) => {
                      setPayrollDate({
                        payrollMonth: e.target.value,
                        payrollYear: payrollDate.payrollYear,
                      });
                      // getPayroll();
                    }}
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <div className="form-inline ml-3">
                  <label>Year</label>
                  <input
                    type="number"
                    style={{ width: "100px" }}
                    className="form-control mx-2"
                    value={payrollDate.payrollYear}
                    onChange={(e) => {
                      setPayrollDate({
                        payrollMonth: payrollDate.payrollMonth,
                        payrollYear: e.target.value,
                      });
                      // getPayroll();
                    }}
                  />
                </div>
              </div>
              <Nav tabs>
                {deductions.map((deduction, index) => {
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === deduction.slug,
                        })}
                        onClick={() => {
                          toggle(deduction.slug);
                        }}
                      >
                        <span className="text-capitalize">
                          {deduction.name}
                        </span>
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>

              {payroll.length ? (
                <TabContent activeTab={activeTab} className="pt-0">
                  {deductions.map((deduction, index) => {
                    return (
                      <TabPane tabId={deduction.slug} key={index}>
                        <Row>
                          <div className="col-md-12 w-100">
                            <div className="table-responsive">
                              <table className="table table-striped custom-table mb-0 datatable">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    {activeTab === "pension" && (
                                      <>
                                        <th>Pension Admin</th>
                                        <th>Pension Number</th>
                                      </>
                                    )}

                                    {deductions.map((setting, index) => {
                                      return (
                                        <th
                                          className={classnames({
                                            "d-none":
                                              activeTab !== setting.slug,
                                          })}
                                          key={index}
                                        >
                                          <span className="text-capitalize">
                                            {setting.name}
                                          </span>
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {payroll.map((employee, index) => {
                                    return (
                                      <tr key={index}>
                                        <th>{index + 1}</th>
                                        <th>
                                          {employee.user?.personalInfo?.name
                                            .first +
                                            " " +
                                            employee.user?.personalInfo?.name
                                              .last}
                                        </th>
                                        {activeTab === "pension" && (
                                          <>
                                            <th>
                                              {employee.user?.bankDetails
                                                ?.pensionAdmin ?? "..."}
                                            </th>
                                            <th>
                                              {employee.user?.bankDetails
                                                ?.pensionNumber ?? "..."}
                                            </th>
                                          </>
                                        )}
                                        {employee.deductions.map(
                                          (deduction, index) => {
                                            return (
                                              <th
                                                key={index}
                                                className={classnames({
                                                  "d-none":
                                                    activeTab !==
                                                    deduction.slug,
                                                })}
                                              >
                                                {deduction.total}
                                              </th>
                                            );
                                          }
                                        )}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Row>
                      </TabPane>
                    );
                  })}
                </TabContent>
              ) : (
                <div
                  className="w-100 d-flex justify-content-center align-items-center bg-white"
                  style={{ height: "250px" }}
                >
                  <p>No Records</p>
                </div>
              )}
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeductionReports));
