import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";
import { addAllDeductions } from "../deduction-settings/deductionAction";
import { addAllSettings } from "../settings/settingsAction";

class Topbar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      employee: null,
      notifications: [],
      cloudUrl: process.env.REACT_APP_CLOUD_DRIVE_FRONTEND,
      collaborationUrl: process.env.REACT_APP_COLLABORATION_FRONTEND,
    };
  }
  componentWillMount() {
    this._isMounted = true;
    const cookies = new Cookies();
    const id = cookies.get("userId");

    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/getByUserId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employee: result.employee,
        });
      });

    fetch(`${URLS.backendNotifications}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          notifications: result.notifications || [],
        });
      });

    if (this._isMounted) {
      fetch(`${URLS.backendApp}/set-up`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log(result);
          this.props.addAllDeductions(result.deductionSettings);
          this.props.addAllSettings(result.settings);
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getDateDiff = (date) => {
    let createDate = moment(date);
    let now = moment(new Date());
    let diff = now.diff(createDate);
    diff = parseInt(diff / 1000);
    if (diff < 60) {
      return diff + " seconds ago";
    }
    diff = parseInt(diff / 60);
    if (diff < 60) {
      if (diff === 1) return "a min ago";
      else return diff + " mins ago";
    }

    diff = parseInt(diff / 60);
    if (diff < 24) {
      if (diff === 1) return "an hour ago";
      else return diff + " hours ago";
    }

    diff = parseInt(diff / 24);
    if (diff < 30) {
      if (diff === 1) return "a day ago";
      else return diff + " days ago";
    }

    diff = parseInt(diff / 30);
    if (diff === 1) return "a month ago";
    else return diff + " months ago";
  };

  handleLogOut = () => {
    const cookies = new Cookies();
    const token = cookies.get("token");

    fetch(URLS.backendUsers + "/logOut", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {})
      .catch((error) => {
        console.log("User logged out error:", error);
      });

    cookies.remove("token");
    cookies.remove("company_id");
    cookies.remove("user");
    // cookies.remove("");
    cookies.remove("isSuperAdmin");
    cookies.remove("isAdmin");
    cookies.remove("userId");
    cookies.remove("isLoggedIn");
    window.location.href = "/login";
  };

  render() {
    const {
      employee,
      notifications,
      type,
      cloudUrl,
      collaborationUrl,
    } = this.state;
    const employeeName = employee ? employee.personalInfo.name.first : "";
    const employeeImage =
      employee && employee.emergencyInfo && employee.emergencyInfo.image
        ? URLS.backendStatic + "/" + employee.emergencyInfo.image
        : URLS.landingPage + "/assets/img/profiles/avatar-02.jpg";
    return (
      <div className="header">
        {/* <!-- Logo --> */}
        <div className="header-left">
          <Link to={`${URLS.dashboard}`} className="logo">
            <img
              src={`${URLS.serverBaseUrl}/public/${this.props.settings.companyImage}`}
              style={{ width: "120px" }}
            />
          </Link>
        </div>
        {/* <!-- /Logo --> */}

        {/* <a id="toggle_btn" href="javaScript:Void(0);">
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a> */}

        {/* <!-- Header Title --> */}
        <div className="page-title-box d-flex">
          {/* <img
            style={{ height: "30px", width: "30px" }}
            src={URLS.landingPage + "/assets/img/imgpsh_fullsize_anim.png"}
            alt=""
          />
          <h3 style={{ display: "inline" }}> Mudiame HR</h3> */}

          <div style={{ display: "none", marginLeft: "50px" }}>
            <a
              href={cloudUrl}
              style={{
                color: "#fff",
                fontWeight: "bold",
                marginLeft: "50px",
              }}
            >
              Cloud Drive
            </a>
            <a
              href={collaborationUrl + "/calendar"}
              style={{
                color: "#fff",
                fontWeight: "bold",
                marginLeft: "50px",
              }}
            >
              Calendar
            </a>
            <a
              href={collaborationUrl + "/messenger"}
              style={{
                color: "#fff",
                fontWeight: "bold",
                marginLeft: "50px",
              }}
            >
              Messenger
            </a>
            <a
              href="/approvals"
              style={{
                color: "#fff",
                fontWeight: "bold",
                marginLeft: "50px",
              }}
            >
              Fund Approval
            </a>
          </div>
        </div>
        {/* <!-- /Header Title --> */}

        {/* <a id="mobile_btn" className="mobile_btn" href="#sidebar">
          <i className="fa fa-bars"></i>
        </a> */}

        {/* <!-- Header Menu --> */}
        <ul className="nav user-menu">
          {/* <!-- Flag --> */}
          <li className="nav-item dropdown has-arrow flag-nav">
            {/* <a
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="javaScript:Void(0);"
              role="button"
            >
              <img
                src={URLS.landingPage + "/assets/img/flags/us.png"}
                alt=""
                height="20"
              />{" "}
              English&nbsp;
            </a> */}
            {/* <div className="dropdown-menu dropdown-menu-right">
              <a href="javaScript:void(0);" className="dropdown-item">
                <img
                  src={URLS.landingPage + "/assets/img/flags/us.png"}
                  alt=""
                  height="16"
                />{" "}
                English&nbsp;
              </a>
              <a href="javaScript:void(0);" className="dropdown-item">
                <img
                  src={URLS.landingPage + "/assets/img/flags/fr.png"}
                  alt=""
                  height="16"
                />{" "}
                French&nbsp;
              </a>
              <a href="javaScript:void(0);" className="dropdown-item">
                <img
                  src={URLS.landingPage + "/assets/img/flags/es.png"}
                  alt=""
                  height="16"
                />{" "}
                Spanish&nbsp;
              </a>
              <a href="javaScript:void(0);" className="dropdown-item">
                <img
                  src={URLS.landingPage + "/assets/img/flags/de.png"}
                  alt=""
                  height="16"
                />{" "}
                German&nbsp;
              </a>
            </div> */}
          </li>
          {/* <!-- /Flag --> */}

          {/* <!-- Notifications --> */}
          <li className="nav-item dropdown">
            <a
              href="javaScript:Void(0);"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <i className="fa fa-bell-o"></i>
              {notifications && notifications.length ? (
                <span class="badge badge-pill">{notifications.length}</span>
              ) : null}
            </a>
            <div className="dropdown-menu notifications">
              <div className="topnav-dropdown-header">
                <span className="notification-title">Notifications</span>
                {/* <a href='javaScript:void(0)' className='clear-noti'> Clear All </a> */}
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  {notifications &&
                    notifications.map((notification) => {
                      return (
                        <li className="notification-message">
                          <a href={URLS.dashboard + "/" + notification.target}>
                            <div className="media">
                              <span
                                style={{
                                  width: "38px",
                                  height: "38px",
                                  lineHeight: "38px",
                                }}
                              >
                                {/* <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-02.jpg'} /> */}
                              </span>
                              <div className="media-body">
                                <p className="noti-details">
                                  {ReactHtmlParser(notification.message)}
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    {this.getDateDiff(notification.createDate)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>
              {/* <div className='topnav-dropdown-footer'>
                                <Link to={`${URLS.activities}`}>View all Notifications</Link>
                            </div> */}
            </div>
          </li>
          {/* <!-- /Notifications --> */}

          {/* <!-- Message Notifications --> */}
          <li className="nav-item dropdown">
            <a
              href={collaborationUrl + "/messenger"}
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <i className="fa fa-comment-o"></i>
            </a>
            {/* <div className='dropdown-menu notifications'>
                            <div className='topnav-dropdown-header'>
                                <span className='notification-title'>Messages</span>
                                <a href='javascript:void(0)' className='clear-noti'> Clear All </a>
                            </div>
                            <div className='noti-content'>
                                <ul className='notification-list'>
                                    <li className='notification-message'>
                                        <a href='http://app.petrobasegroup.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-09.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'>Richard Miles </span>
                                                    <span className='message-time'>12:28 AM</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.petrobasegroup.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-02.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'>John Doe</span>
                                                    <span className='message-time'>6 Mar</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.petrobasegroup.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-03.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'> Tarah Shropshire </span>
                                                    <span className='message-time'>5 Mar</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.petrobasegroup.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-05.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'>Mike Litorus</span>
                                                    <span className='message-time'>3 Mar</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className='notification-message'>
                                        <a href='http://app.petrobasegroup.com/messenger'>
                                            <div className='list-item'>
                                                <div className='list-left'>
                                                    <span className='avatar'>
                                                        <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-08.jpg' }/>
                                                    </span>
                                                </div>
                                                <div className='list-body'>
                                                    <span className='message-author'> Catherine Manseau </span>
                                                    <span className='message-time'>27 Feb</span>
                                                    <div className='clearfix'></div>
                                                    <span className='message-content'>Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='topnav-dropdown-footer'>
                                <a href='http://app.petrobasegroup.com/messenger'>View all Messages</a>
                            </div>
                        </div> */}
          </li>
          {/* <!-- /Message Notifications --> */}

          <li className="nav-item dropdown has-arrow main-drop">
            <a
              href="javaScript:void(0)"
              className="dropdown-toggle nav-link"
              data-toggle="dropdown"
            >
              <span className="user-img">
                <img style={{ height: "30px" }} src={employeeImage} alt="" />
                <span className="status online"></span>
              </span>
              <span>&nbsp;{employeeName}&nbsp;</span>
            </a>
            <div className="dropdown-menu">
              <Link
                className="dropdown-item"
                to={employee ? URLS.profile + "/" + employee._id : ""}
              >
                My Profile
              </Link>
              {/* <a className="dropdown-item" href={`${URLS.settings}`}>
                Settings
              </a> */}
              <span
                style={{ cursor: "pointer" }}
                className="dropdown-item"
                onClick={() => this.handleLogOut()}
              >
                Logout
              </span>
            </div>
          </li>
        </ul>
        {/* <!-- /Header Menu --> */}

        {/* <!-- Mobile Menu --> */}
        <div className="dropdown mobile-user-menu">
          <a
            href="javaScript:void(0)"
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to={employee ? URLS.profil + "/" + employee._id : ""}
            >
              My Profile
            </Link>
            <a className="dropdown-item" href={`${URLS.settings}`}>
              Settings
            </a>
            <a className="dropdown-item" href={`${URLS.login}`}>
              Logout
            </a>
          </div>
        </div>
        {/* <!-- /Mobile Menu --> */}
      </div>
    );
  }
}

// export default Topbar;

const mapStateToProps = (state) => {
  return {
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAllDeductions: (deductions) => dispatch(addAllDeductions(deductions)),
    addAllSettings: (settings) => dispatch(addAllSettings(settings)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
