import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { isEnterKey } from "../../utils";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { URLS } from "../urls";
import { toast } from "react-toastify";

function PayHistory() {
  const date = new Date();
  const [activeTab, setActiveTab] = useState("1");
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });
  const [deductions, setDeductions] = useState([]);
  const [payroll, setPayroll] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getPayroll();

    return () => {};
  }, [payrollDate]);

  async function getPayroll() {
    try {
      const token = "dummy token";
      const payrollResonse = await fetch(
        `${URLS.backendPayroll}/get-payroll?payrollMonth=${payrollDate.payrollMonth}&payrollYear=${payrollDate.payrollYear}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const payrollResult = await payrollResonse.json();
      const { payroll } = payrollResult;
      if (payroll.length) {
        setDeductions(payroll[0].deductions);
        setPayroll(payroll);
      } else {
        setPayroll([]);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Pay History</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="mt-3">
              <div className="d-flex col-md-6 my-4">
                <div className="form-inline">
                  <label>Month</label>
                  <select
                    className="form-control mx-2"
                    value={payrollDate.payrollMonth}
                    onChange={(e) => {
                      setPayrollDate({
                        payrollMonth: e.target.value,
                        payrollYear: payrollDate.payrollYear,
                      });
                    }}
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <div className="form-inline ml-3">
                  <label>Year</label>
                  <input
                    type="number"
                    style={{ width: "100px" }}
                    className="form-control mx-2"
                    value={payrollDate.payrollYear}
                    onChange={(e) => {
                      setPayrollDate({
                        payrollMonth: payrollDate.payrollMonth,
                        payrollYear: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Normal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Prorated
                  </NavLink>
                </NavItem>
              </Nav>
              {payroll.length ? (
                <TabContent activeTab={activeTab} className="pt-0">
                  <TabPane tabId="1">
                    <Row>
                      <div className="col-md-12 w-100">
                        <div className="table-responsive">
                          <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Job Grade</th>
                                <th>Basic</th>
                                <th>Housing</th>
                                <th>Transport</th>
                                <th>Medical</th>
                                <th>Utility</th>
                                <th>Paid Leave</th>
                                <th>Gratuity</th>

                                <th>Bonuses</th>

                                <th>Gross Pay</th>

                                {deductions.map((setting, index) => {
                                  return (
                                    <th className="text-capitalize" key={index}>
                                      {setting.name}
                                    </th>
                                  );
                                })}

                                <th>Other Deductions</th>
                                <th>Remark</th>
                                <th>Net Pay</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {payroll.map((employee, index) => {
                                if (employee.type === "normal")
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {employee.user?.personalInfo?.name
                                          .first +
                                          " " +
                                          employee.user?.personalInfo?.name
                                            .last}
                                      </td>
                                      <td>{employee.gradeName}</td>
                                      <td>{employee.basic}</td>
                                      <td>{employee.housing}</td>
                                      <td>{employee.transport}</td>
                                      <td>{employee.medical}</td>
                                      <td>{employee.utility}</td>
                                      <td>{employee.paidLeave}</td>
                                      <td>{employee.gratuity}</td>

                                      <td>{employee.bonuses}</td>
                                      <td>{employee.grossPay}</td>

                                      {employee.deductions.map(
                                        (deduction, index) => {
                                          return (
                                            <td key={index}>
                                              {deduction.total}
                                            </td>
                                          );
                                        }
                                      )}

                                      <td>{employee.otherDeductions}</td>
                                      <td>{employee.remark}</td>
                                      <td>{employee.netPay}</td>
                                      <td style={{ fontSize: "22px" }}>
                                        <a
                                          className="mx-2 text-primary"
                                          target="blank"
                                          href={
                                            URLS.backendPayroll +
                                            "/view-payslip/" +
                                            employee._id
                                          }
                                          title="View slip"
                                        >
                                          <i className="la la-eye"></i>
                                        </a>
                                        {
                                          <a
                                            className="mx-2 text-secondary"
                                            href={
                                              URLS.backendPayroll +
                                              "/download-payslip/" +
                                              employee._id
                                            }
                                            title="Download slip"
                                            download="true"
                                          >
                                            <i className="la la-download"></i>
                                          </a>
                                        }
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <div className="col-md-12 w-100">
                        <div className="table-responsive">
                          <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Job Grade</th>
                                <th>Basic</th>
                                <th>Housing</th>
                                <th>Transport</th>
                                <th>Medical</th>
                                <th>Utility</th>
                                <th>Paid Leave</th>
                                <th>Gratuity</th>

                                <th>Bonuses</th>

                                <th>Gross Pay</th>

                                {deductions.map((setting, index) => {
                                  return (
                                    <th className="text-capitalize" key={index}>
                                      {setting.name}
                                    </th>
                                  );
                                })}

                                <th>Other Deductions</th>
                                <th>Remark</th>
                                <th>Net Pay</th>
                                <th>Payslip</th>
                              </tr>
                            </thead>
                            <tbody>
                              {payroll.map((employee, index) => {
                                if (employee.type === "prorated")
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {employee.user?.personalInfo?.name
                                          .first +
                                          " " +
                                          employee.user?.personalInfo?.name
                                            .last}
                                      </td>
                                      <td>{employee.gradeName}</td>
                                      <td>{employee.basic}</td>
                                      <td>{employee.housing}</td>
                                      <td>{employee.transport}</td>
                                      <td>{employee.medical}</td>
                                      <td>{employee.utility}</td>
                                      <td>{employee.paidLeave}</td>
                                      <td>{employee.gratuity}</td>

                                      <td>{employee.bonuses}</td>
                                      <td>{employee.grossPay}</td>

                                      {employee.deductions.map(
                                        (deduction, index) => {
                                          return (
                                            <td key={index}>
                                              {deduction.total}
                                            </td>
                                          );
                                        }
                                      )}

                                      <td>{employee.otherDeductions}</td>
                                      <td>{employee.remark}</td>
                                      <td>{employee.netPay}</td>
                                      <td style={{ fontSize: "22px" }}>
                                        <a
                                          className="mx-2 text-primary"
                                          target="blank"
                                          href={
                                            URLS.backendPayroll +
                                            "/view-payslip/" +
                                            employee._id
                                          }
                                          title="View slip"
                                        >
                                          <i className="la la-eye"></i>
                                        </a>
                                        <a
                                          className="mx-2 text-secondary"
                                          href={
                                            URLS.backendPayroll +
                                            "/download-payslip/" +
                                            employee._id
                                          }
                                          title="Download slip"
                                          download="true"
                                        >
                                          <i className="la la-download"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Row>
                  </TabPane>
                </TabContent>
              ) : (
                <div
                  className="w-100 d-flex justify-content-center align-items-center bg-white"
                  style={{ height: "250px" }}
                >
                  <p>...</p>
                </div>
              )}

              <div className="mt-2 d-none">
                <button className="btn btn-primary">Salary Summary</button>
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    // deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PayHistory));
