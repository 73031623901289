import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link, useHistory } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { toast } from "react-toastify";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { addDeduction, deleteDeduction } from "./deductionAction";

function DeductionSettings(props) {
  const [settingsName, setSettingsName] = useState("");
  const [btnText, setBtnText] = useState("Save Settings");
  const [deleteBtnText, setDeleteBtnText] = useState("Delete");
  const [newSettingsName, setNewSettingsName] = useState("");
  const history = useHistory();
  const [deductionSettings, setDeductionSettings] = useState({
    percentage: 0,
    enabled: false,
    basic: false,
    housing: false,
    medical: false,
    transport: false,
    utility: false,
    paidLeave: false,
    gratuity: false,
  });

  useEffect(() => {
    const slug = window.location.pathname.split("/")[3];
    const name = slug.replaceAll("-", " ");
    setSettingsName(name);
    getSettings(slug);

    const unlisten = history.listen((location) => {
      if (!location.pathname.includes("deduction-settings")) return;
      resetState();
      const slug = window.location.pathname.split("/")[3];
      const name = slug.replaceAll("-", " ");
      setSettingsName(name);
      getSettings(slug);
    });

    return () => unlisten();
  }, [settingsName, history]);

  const toggleModal = () => {
    window.$("#delete_employee").modal("toggle");
  };

  const resetState = () => {
    setSettingsName("");
    setBtnText("Save Settings");
    setDeleteBtnText("Delete");
    setNewSettingsName("");
    setDeductionSettings({
      percentage: 0,
      basic: false,
      housing: false,
      medical: false,
      transport: false,
      utility: false,
      paidLeave: false,
      gratuity: false,
    });
  };

  const getSettings = async (slug) => {
    try {
      if (slug === "add-new") return;
      const token = "dummy token";
      const res = await fetch(`${URLS.backendDeductionSettings}/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const data = await res.json();
      if (!res.ok) {
        throw Error(data);
      } else {
        setDeductionSettings({
          ...data.deductionSettings,
        });
        if (data.deductionSettings?.name)
          setSettingsName(data.deductionSettings.name);
      }
    } catch (err) {
      console.log(err);
      toast("Unable to get settings, please try again", {
        type: "error",
      });
    } finally {
    }
  };

  const deleteSetting = async (slug) => {
    toggleModal();
    try {
      setDeleteBtnText("Please wait...");
      const token = "dummy token";
      const res = await fetch(
        `${URLS.backendDeductionSettings}/delete/${slug}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      const data = await res.json();
      if (!res.ok) {
        throw Error(data);
      } else {
        toast("Settings deleted", {
          type: "success",
        });
        resetState();
        props.deleteDeduction(slug);
        props.history.push(`add-new`);
      }
    } catch (err) {
      console.log(err);
      toast("Unable to get settings, please try again", {
        type: "error",
      });
    } finally {
      setDeleteBtnText("Delete");
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setBtnText("Please wait...");
      const token = "dummy token";
      const thedeductionSettings = {
        ...deductionSettings,
        createdBy: props.User._id,
        name: settingsName === "add new" ? newSettingsName : settingsName,
      };

      const res = await fetch(`${URLS.backendDeductionSettings}/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          deductionSettings: thedeductionSettings,
          isNew: settingsName === "add new",
        }),
      });

      const data = await res.json();
      if (!res.ok) {
        throw Error(data.message);
      } else {
        console.log(data);
        toast("Settings saved", {
          type: "success",
        });
        setDeductionSettings({
          ...data.deductionSettings,
        });
        props.addDeduction({
          ...data.deductionSettings,
        });
        if (settingsName === "add new") {
          props.history.push(`${data.deductionSettings.slug}`);
        }
      }
    } catch (err) {
      toast(err ? err.toString() : "Unable to submit, please try again", {
        type: "error",
      });
    } finally {
      setBtnText("Save Settings");
    }
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Deduction Settings</BreadcrumbItem>
                  <BreadcrumbItem active>{settingsName}</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div class="p-3">
              <form onSubmit={(e) => submitForm(e)} autoComplete={false}>
                <h2 className="text-capitalize h3">
                  {settingsName}{" "}
                  {settingsName === "add new" && "Deduction settings"}
                </h2>

                <div className="col-md-8 pl-0">
                  {settingsName === "add new" && (
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        required
                        className="form-control"
                        type="text"
                        value={newSettingsName}
                        name="newSettingsName"
                        onChange={(e) => setNewSettingsName(e.target.value)}
                      />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group">
                        <label>Percentage</label>
                        <input
                          className="form-control"
                          type="text"
                          value={deductionSettings.percentage}
                          name="percentage"
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              percentage: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label>Enable</label>
                      <div
                        className="onoffswitch ml-0"
                        onClick={(e) => {
                          setDeductionSettings({
                            ...deductionSettings,
                            enabled: !deductionSettings.enabled,
                          });
                        }}
                      >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          checked={deductionSettings.enabled}
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <p>{deductionSettings.percentage}% of </p>
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="form-check"
                        style={{
                          height: "44px",
                          padding: "6px 0",
                          display: "inline-block",
                        }}
                      >
                        <input
                          style={{ height: "30px", width: "30px" }}
                          type="checkbox"
                          checked={deductionSettings.basic}
                          name="basic"
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              basic: !deductionSettings.basic,
                            })
                          }
                        />
                        <label
                          style={{ margin: "10px", verticalAlign: "bottom" }}
                        >
                          Basic
                        </label>
                      </div>

                      <div
                        className="form-check d-block"
                        style={{
                          height: "44px",
                          padding: "6px 0",
                          display: "inline-block",
                        }}
                      >
                        <input
                          style={{ height: "30px", width: "30px" }}
                          type="checkbox"
                          checked={deductionSettings.housing}
                          name="housing"
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              housing: !deductionSettings.housing,
                            })
                          }
                        />
                        <label
                          style={{ margin: "10px", verticalAlign: "bottom" }}
                        >
                          Housing
                        </label>
                      </div>

                      <div
                        className="form-check d-block"
                        style={{
                          height: "44px",
                          padding: "6px 0",
                          display: "inline-block",
                        }}
                      >
                        <input
                          style={{ height: "30px", width: "30px" }}
                          type="checkbox"
                          checked={deductionSettings.medical}
                          name="medical"
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              medical: !deductionSettings.medical,
                            })
                          }
                        />
                        <label
                          style={{ margin: "10px", verticalAlign: "bottom" }}
                        >
                          Medical
                        </label>
                      </div>

                      <div
                        className="form-check d-block"
                        style={{
                          height: "44px",
                          padding: "6px 0",
                          display: "inline-block",
                        }}
                      >
                        <input
                          style={{ height: "30px", width: "30px" }}
                          type="checkbox"
                          checked={deductionSettings.transport}
                          name="transport"
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              transport: !deductionSettings.transport,
                            })
                          }
                        />
                        <label
                          style={{ margin: "10px", verticalAlign: "bottom" }}
                        >
                          Transport
                        </label>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="form-check d-block"
                        style={{
                          height: "44px",
                          padding: "6px 0",
                          display: "inline-block",
                        }}
                      >
                        <input
                          style={{ height: "30px", width: "30px" }}
                          type="checkbox"
                          checked={deductionSettings.utility}
                          name="utility"
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              utility: !deductionSettings.utility,
                            })
                          }
                        />
                        <label
                          style={{ margin: "10px", verticalAlign: "bottom" }}
                        >
                          Utility
                        </label>
                      </div>

                      <div
                        className="form-check d-block"
                        style={{
                          height: "44px",
                          padding: "6px 0",
                          display: "inline-block",
                        }}
                      >
                        <input
                          style={{ height: "30px", width: "30px" }}
                          type="checkbox"
                          checked={deductionSettings.paidLeave}
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              paidLeave: !deductionSettings.paidLeave,
                            })
                          }
                          name="paidLeave"
                        />
                        <label
                          style={{ margin: "10px", verticalAlign: "bottom" }}
                        >
                          Paid Leave
                        </label>
                      </div>

                      <div
                        className="form-check d-block"
                        style={{
                          height: "44px",
                          padding: "6px 0",
                          display: "inline-block",
                        }}
                      >
                        <input
                          style={{ height: "30px", width: "30px" }}
                          type="checkbox"
                          checked={deductionSettings.gratuity}
                          onChange={(e) =>
                            setDeductionSettings({
                              ...deductionSettings,
                              gratuity: !deductionSettings.gratuity,
                            })
                          }
                          name="gratuity"
                        />
                        <label
                          style={{ margin: "10px", verticalAlign: "bottom" }}
                        >
                          Gratuity
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-primary mt-3">
                      {btnText}
                    </button>
                  </div>

                  <div
                    className="col-md-2"
                    style={
                      ["add new", "pension", "payee tax", "nsitf"].includes(
                        settingsName
                      )
                        ? { display: "none" }
                        : {}
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-danger mt-3"
                      onClick={toggleModal}
                    >
                      {deleteBtnText}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}

        {/*<!-- Delete Setting Modal -->*/}
        <div
          className="modal custom-modal fade"
          id="delete_employee"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3 className="text-capitalize">
                    Delete {deductionSettings.name}
                  </h3>
                  <p>Are you sure want to delete this setting?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a
                        href="javascript:void(0);"
                        className="btn btn-primary continue-btn"
                        onClick={() => deleteSetting(deductionSettings.slug)}
                      >
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href="javascript:void(0);"
                        data-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<!-- /Delete Setting Modal -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteDeduction: (slug) => dispatch(deleteDeduction(slug)),
    addDeduction: (deduction) => dispatch(addDeduction(deduction)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeductionSettings));
