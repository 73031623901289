import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Spinner, Form } from "reactstrap";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";

import { toast } from "react-toastify";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";

import "../../../src/assets/css/ga-styles.css";
import AddJobGradeModal from "../employees/AddJobGradeModal";
import EditJobGradeModal from "../employees/EditJobGradeModal";

function JobGrade() {
  const [loader, setLoader] = useState(false);
  const [jobGrades, setJobGrade] = useState([]);
  const [selectedJobGrade, setSelectedJobGrade] = useState({
    name: "",
    description: "",
    type: "",
    salary: {
      basic: 0,
      housing: 0,
      medical: 0,
      transport: 0,
      utility: 0,
      paidLeave: 0,
      gratuity: 0,
      offshoreAllowances: 0,
      transitAllowances: 0,
      overTimeAllowances: 0,
    },
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const toggle = () => setShowAddModal(!showAddModal);

  const [foundModal, setFoundModal] = useState(false);
  const toggleFound = () => setFoundModal(!foundModal);

  const [showEditModal, setShowEditModal] = useState(false);
  const changeEditJobState = () => setShowEditModal(!showEditModal);

  const [found, setFound] = useState([]);

  useEffect(() => {
    const token = "dummy token";
    fetch(`${URLS.backendJobGrades}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setJobGrade([...result.jobGrades]);
      })
      .catch((err) => {
        toast("Unable to get Job Grades, please refresh your browser", {
          type: "error",
        });
      });

    return () => {};
  }, []);

  const changeShowJobState = () => {
    toggle();
  };
  const updateJobGradesList = (item) => {
    setJobGrade([...jobGrades, item]);
  };

  const updateEditedJobGradesList = (item) => {
    alert(item);
  };

  const handleEdit = (jobId) => {
    setSelectedJobGrade(jobGrades.find((job) => job._id === jobId));
    changeEditJobState();
  };

  const setUpDelete = (jobId) => {
    setSelectedJobGrade(jobGrades.find((job) => job._id === jobId));
    window.$("#delete_job_grade").modal("toggle");
  };

  const handleDeleteJobGrade = () => {
    window.$("#delete_job_grade").modal("toggle");
    setLoader(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobGrades}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        jobGradeId: selectedJobGrade._id,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          toast("Job grade deleted", {
            type: "success",
          });
          setJobGrade(
            jobGrades.filter((job) => job._id !== selectedJobGrade._id)
          );
        } else {
          if (result.found) {
            setFound(
              result.found.map((employee) => ({
                ...employee.userId,
                jobGradeId: employee.jobInfo.grade,
              }))
            );
            toggleFound();
          } else {
            throw Error();
          }
        }
      })
      .catch((err) => {
        toast("Job grade not deleted", {
          type: "error",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const setJobGradeChange = (e) => {
    found[parseInt(e.target.name)].jobGradeId = e.target.value;

    setFound([...found]);
  };

  const submitChanges = (e) => {
    e.preventDefault();
    setLoader(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobGrades}/edit-employees-jobgrade`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        users: found,
        jobGradeId: selectedJobGrade._id,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Employees job grade changed, and job grade deleted", {
            type: "success",
          });
          setJobGrade(
            jobGrades.filter((job) => job._id !== selectedJobGrade._id)
          );
          toggleFound();
        } else {
          throw Error;
        }
      })
      .catch((err) => {
        toast("Unable to update job grades", {
          type: "error",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-md-9 col-sm-8 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Job Grade</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-md-3 col-sm-4 text-right m-b-30">
                <a
                  href="#"
                  className="btn add-btn"
                  data-toggle="modal"
                  data-target="#add_job_grade"
                  onClick={changeShowJobState}
                >
                  <i className="fa fa-plus"></i> Add Job Grade
                </a>
              </div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- Content Starts -->*/}
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-striped custom-table mb-0 datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Salary Type</th>
                        <th>Basic</th>
                        <th>Housing</th>
                        <th>Medical</th>
                        <th>Transport</th>
                        <th>Utility</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobGrades.map((job, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{job.name}</td>
                            <td className="text-capitalize">
                              {job.type ?? "..."}
                            </td>
                            <th>{job.salary.basic}</th>
                            <th>{job.salary.housing}</th>
                            <th>{job.salary.medical}</th>
                            <th>{job.salary.transport}</th>
                            <th>{job.salary.utility}</th>
                            <th style={{ fontSize: "22px" }}>
                              {/*<button
                                className="mr-2 text-secondary"
                                title="view"
                              >
                                <i className="la la-eye"></i>
                              </button>
                              */}
                              {loader && selectedJobGrade._id === job._id ? (
                                <Spinner size="sm" color="primary" />
                              ) : (
                                <>
                                  <button
                                    className="mr-2 text-secondary"
                                    title="Edit"
                                    onClick={() => handleEdit(job._id)}
                                  >
                                    <i className="la la-edit"></i>
                                  </button>

                                  <button
                                    className="text-danger"
                                    title="Delete"
                                    onClick={() => setUpDelete(job._id)}
                                  >
                                    <i className="la la-trash"></i>
                                  </button>
                                </>
                              )}
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
          <div>
            <AddJobGradeModal
              updateJobGradesList={updateJobGradesList}
              isShow={showAddModal}
              changeShowJobState={changeShowJobState}
            />
            <EditJobGradeModal
              key={selectedJobGrade._id}
              updateEditedJobGradesList={updateEditedJobGradesList}
              jobGrade={selectedJobGrade}
              isShow={showEditModal}
              changeEditJobState={changeEditJobState}
              setLoader={setLoader}
            />

            {/*<!-- Delete Employee Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_job_grade"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Job Grade</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                            onClick={handleDeleteJobGrade}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Found Employee Modal -->*/}

            {
              <Modal isOpen={foundModal} toggle={toggleFound} centered={true}>
                <ModalHeader toggle={toggleFound}>
                  Employees in {selectedJobGrade.name}
                </ModalHeader>
                <ModalBody>
                  <Alert color="danger">
                    The following employees are attached to{" "}
                    {selectedJobGrade.name} job grade, change them before you
                    delete
                  </Alert>

                  <form onSubmit={submitChanges}>
                    <div class="table-responsive">
                      <table class="table table-striped custom-table mb-0">
                        <thead>
                          <tr>
                            <th>Employees</th>
                            <th>Job Grade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {found
                            ? found.map((employee, index) => {
                                const image = employee.image;
                                return (
                                  <tr key={index}>
                                    <td>
                                      <h2 class="table-avatar">
                                        <a
                                          href={`${URLS.profile}/${employee._id}`}
                                          target="blank"
                                          className="avatar"
                                        >
                                          <img
                                            alt=""
                                            src={
                                              image
                                                ? URLS.backendStatic +
                                                  "/" +
                                                  image
                                                : "assets/img/profiles/avatar-09.jpg"
                                            }
                                          />
                                        </a>
                                        <a
                                          href={`${URLS.profile}/${employee._id}`}
                                          target="blank"
                                        >
                                          {employee.name.first +
                                            " " +
                                            employee.name.last}
                                        </a>
                                      </h2>
                                    </td>
                                    <td className="text-right">
                                      <select
                                        className="form-control"
                                        key={index}
                                        onChange={(e) => setJobGradeChange(e)}
                                        name={index}
                                        value={found[index].jobGradeId}
                                        required
                                      >
                                        <option value="" selected>
                                          {selectedJobGrade.name}
                                        </option>
                                        {jobGrades.length &&
                                          jobGrades.map((job) => {
                                            if (
                                              job._id !== selectedJobGrade._id
                                            )
                                              return (
                                                <option
                                                  value={job._id}
                                                  key={job._id}
                                                >
                                                  {job.name}
                                                </option>
                                              );
                                          })}
                                      </select>
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>

                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? "Please wait" : "Save changes"}
                      </button>
                    </div>
                  </form>
                </ModalBody>
                {/*<ModalFooter>
                  <Button color="secondary" onClick={toggleFound}>
                    Close
                  </Button>
                </ModalFooter>
                */}
              </Modal>
            }
          </div>
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(JobGrade));
