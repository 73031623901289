import React from 'react';
import { URLS } from '../urls';
import { Form, Col, Row, Image } from 'react-bootstrap';
export default class AddLeaveModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonText : 'Submit',
            isWaiting : false,
            leave : {
                name : '',
                days : 0,
                carry : false,
                carryMax : 0,
                earned : false,
                forGender : 'ALL',
                isActive : false,
                customPolicies : []
            }
        };
    }
    updateLeaveName = (e) =>{
        const{leave} = this.state;
        leave.name = e.target.value;
        this.setState({
            leave
        })
    }
    updateLeaveDays = (e) =>{
        const{leave} = this.state;
        leave.days = e.target.value;
        this.setState({
            leave
        })
    }
    updateLeaveCarryForward = (e) =>{
        console.log("YO")
        const{leave} = this.state;
        leave.carry = !leave.carry;
        this.setState({
            leave
        })
    }
    updateLeaveCarryMax = (e) =>{
        const{leave} = this.state;
        leave.carryMax = e.target.value;
        this.setState({
            leave
        })
    }
    updateLeaveEarned = (e) =>{
        const{leave} = this.state;
        leave.earned = !leave.earned;
        this.setState({
            leave
        })
    }
    updateLeaveForGender = (e) =>{
        const{leave} = this.state;
        leave.forGender = e.target.value;
        this.setState({
            leave
        })
    }
    updateLeaveIsActive = (e) =>{
        const{leave} = this.state;
        leave.isActive = !leave.isActive;
        this.setState({
            leave
        })
    }
    // updateLeaveCustomPolicies = (e) =>{
    //     const{leave} = this.state;
    //     leave.carryMax = e.target.value;
    //     this.setState({
    //         leave
    //     })
    // }
    submit = (e) => {
        e.preventDefault();
        this.setState({buttonText:'Submitting ...',isWaiting:true})
        const { leave } = this.state;
        const token = 'dummy token'; 
        fetch(`${URLS.backendLeaves}/add`, {
            method: 'POST',
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Token ${token}`
            },
            body: JSON.stringify({leave}),
        }).then(res =>{
            if(res.ok){
                res.json().then((result) => {
                    setTimeout(()=>{
                        window.$("#add_leave").modal("toggle");
                        this.setState({ buttonText: 'Submit', isWaiting: false });
                        this.props.updateLeavesList(result.leave);
                    }, 500);
                });
            }else{
                res.json().then((responseJson) => {
                    console.log(responseJson);
                    alert("Error occurred, Check the form again.");
                    this.setState({ buttonText: 'Submit', isWaiting: false });
                });
            }
        });
    };

	render() {
        const {leave} = this.state;
        return (
                <div id="add_leave" className="modal custom-modal fade" role="dialog">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Leave</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.submit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Leave Type<span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" value={leave.name} onChange={this.updateLeaveName.bind(this)} required/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Days<span className="text-danger">*</span></label>
                                                <input className="form-control" type="number" value={leave.days} onChange={this.updateLeaveDays.bind(this)} required/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Carry Forward<span className="text-danger">*</span></label>
                                                <div className="onoffswitch" style={{marginLeft : '0', marginRight:'auto'}}>
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" 
                                                        id="switch_hospitalisation" checked={leave.carry} />
                                                    <label className="onoffswitch-label" for="switch_hospitalisation" onClick={this.updateLeaveCarryForward.bind(this)}>
                                                        <span className="yesnoswitch-inner"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            {
                                                leave.carry && <div className="form-group">
                                                    <label className="col-form-label">Carry Max</label>
                                                    <input className="form-control" type="number" value={leave.carryMax} onChange={this.updateLeaveCarryMax.bind(this)} required/>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Earned Leave</label>
                                                <div className="onoffswitch" style={{marginLeft : '0', marginRight:'auto'}}>
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" 
                                                        id="switch_hospitalisation" checked={leave.earned} />
                                                    <label className="onoffswitch-label" for="switch_hospitalisation" onClick={this.updateLeaveEarned.bind(this)}>
                                                        <span className="yesnoswitch-inner"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">Available for Gender</label>
                                                <Form.Control as='select' name='department' value={leave.forGender}
                                                    onChange={this.updateLeaveForGender.bind(this)} required>
                                                    <option value='ALL'>All</option>
                                                    <option value='MALE'>Male</option>
                                                    <option value='FEMALE'>Female</option>
                                                </Form.Control>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="col-form-label">is available for Employees</label>
                                                <div className="onoffswitch" style={{marginLeft : '0', marginRight:'auto'}}>
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" 
                                                        id="switch_hospitalisation" checked={leave.isActive} />
                                                    <label className="onoffswitch-label" for="switch_hospitalisation" onClick={this.updateLeaveIsActive.bind(this)}>
                                                        <span className="yesnoswitch-inner"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="submit-section">
                                        <button className="btn btn-primary submit-btn">{this.state.buttonText}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )
    }
}