import React from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";

class Tickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-8 col-4">
                  <h4 className="page-title">Tickets</h4>
                </div>
                <div className="col-sm-4 col-8 text-right m-b-30">
                  <a
                    href="#"
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#add_ticket"
                  >
                    <i className="fa fa-plus"></i> Add Ticket
                  </a>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Search Filter -->*/}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <input type="text" className="form-control floating" />
                    <label className="focus-label">Employee Name</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <select className="select floating">
                      <option> -- Select -- </option>
                      <option> Pending </option>
                      <option> Approved </option>
                      <option> Returned </option>
                    </select>
                    <label className="focus-label">Status</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <select className="select floating">
                      <option> -- Select -- </option>
                      <option> High </option>
                      <option> Low </option>
                      <option> Medium </option>
                    </select>
                    <label className="focus-label">Priority</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <input
                        className="form-control floating datetimepicker"
                        type="text"
                      />
                    </div>
                    <label className="focus-label">From</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus">
                    <div className="cal-icon">
                      <input
                        className="form-control floating datetimepicker"
                        type="text"
                      />
                    </div>
                    <label className="focus-label">To</label>
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <a href="#" className="btn btn-success btn-block">
                    {" "}
                    Search{" "}
                  </a>
                </div>
              </div>
              {/*<!-- /Search Filter -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Ticket Id</th>
                          <th>Ticket Subject</th>
                          <th>Assigned Staff</th>
                          <th>Created Date</th>
                          <th>Last Reply</th>
                          <th>Priority</th>
                          <th className="text-center">Status</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <a href="ticket-view.html">#TKT-0001</a>
                          </td>
                          <td>Laptop Issue</td>
                          <td>
                            <h2 className="table-avatar">
                              <a
                                className="avatar avatar-xs"
                                href="profile.html"
                              >
                                <img
                                  alt=""
                                  src="assets/img/profiles/avatar-10.jpg"
                                />
                              </a>
                              <a href="#">John Smith</a>
                            </h2>
                          </td>
                          <td>5 Jan 2019 07:21 AM</td>
                          <td>5 Jan 2019 11.12 AM</td>
                          <td>
                            <div className="dropdown action-label">
                              <a
                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                High{" "}
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  High
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-warning"></i>{" "}
                                  Medium
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  Low
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="dropdown action-label">
                              <a
                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                New
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-info"></i>{" "}
                                  Open
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-info"></i>{" "}
                                  Reopened
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  On Hold
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  Closed
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  In Progress
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  Cancelled
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#edit_ticket"
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#delete_ticket"
                                >
                                  <i className="fa fa-trash-o m-r-5"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            <a href="ticket-view.html">#TKT-0001</a>
                          </td>
                          <td>Internet Issue</td>
                          <td>
                            <h2 className="table-avatar">
                              <a
                                className="avatar avatar-xs"
                                href="profile.html"
                              >
                                <img
                                  alt=""
                                  src="assets/img/profiles/avatar-08.jpg"
                                />
                              </a>
                              <a href="#">Catherine Manseau</a>
                            </h2>
                          </td>
                          <td>5 Jan 2019 07:21 AM</td>
                          <td>5 Jan 2019 11.12 AM</td>
                          <td>
                            <div className="dropdown action-label">
                              <a
                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-dot-circle-o text-warning"></i>{" "}
                                Medium{" "}
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  High
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-warning"></i>{" "}
                                  Medium
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  Low
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="dropdown action-label">
                              <a
                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                href="#"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-dot-circle-o text-info"></i>{" "}
                                Reopened
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-info"></i>{" "}
                                  Open
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-info"></i>{" "}
                                  Reopened
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  On Hold
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  Closed
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                  In Progress
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                  Cancelled
                                </a>
                              </div>
                            </div>
                          </td>
                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                href="#"
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="material-icons">more_vert</i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#edit_ticket"
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#delete_ticket"
                                >
                                  <i className="fa fa-trash-o m-r-5"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Ticket Modal -->*/}
            <div
              id="add_ticket"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Add Ticket</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Subject</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Id</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Assign Staff</label>
                            <select className="select">
                              <option>-</option>
                              <option>Mike Litorus</option>
                              <option>John Smith</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Client</label>
                            <select className="select">
                              <option>-</option>
                              <option>Delta Infotech</option>
                              <option>International Software Inc</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Priority</label>
                            <select className="select">
                              <option>High</option>
                              <option>Medium</option>
                              <option>Low</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>CC</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Assign</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Assignee</label>
                            <div className="project-members">
                              <a
                                title="John Smith"
                                data-placement="top"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-02.jpg"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Add Followers</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Followers</label>
                            <div className="project-members">
                              <a
                                title="Richard Miles"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-09.jpg"
                                  alt=""
                                />
                              </a>
                              <a
                                title="John Smith"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-10.jpg"
                                  alt=""
                                />
                              </a>
                              <a
                                title="Mike Litorus"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-05.jpg"
                                  alt=""
                                />
                              </a>
                              <a
                                title="Wilmer Deluna"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-11.jpg"
                                  alt=""
                                />
                              </a>
                              <span className="all-team">+2</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Description</label>
                            <textarea className="form-control"></textarea>
                          </div>
                          <div className="form-group">
                            <label>Upload Files</label>
                            <input className="form-control" type="file" />
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Add Ticket Modal -->*/}

            {/*<!-- Edit Ticket Modal -->*/}
            <div
              id="edit_ticket"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Edit Ticket</h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Subject</label>
                            <input
                              className="form-control"
                              type="text"
                              value="Laptop Issue"
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Id</label>
                            <input
                              className="form-control"
                              type="text"
                              readonly
                              value="TKT-0001"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Assign Staff</label>
                            <select className="select">
                              <option>-</option>
                              <option selected>Mike Litorus</option>
                              <option>John Smith</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Client</label>
                            <select className="select">
                              <option>-</option>
                              <option>Delta Infotech</option>
                              <option selected>
                                International Software Inc
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Priority</label>
                            <select className="select">
                              <option>High</option>
                              <option selected>Medium</option>
                              <option>Low</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>CC</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Assign</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Assignee</label>
                            <div className="project-members">
                              <a
                                title="John Smith"
                                data-placement="top"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-02.jpg"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Add Followers</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Ticket Followers</label>
                            <div className="project-members">
                              <a
                                title="Richard Miles"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-09.jpg"
                                  alt=""
                                />
                              </a>
                              <a
                                title="John Smith"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-10.jpg"
                                  alt=""
                                />
                              </a>
                              <a
                                title="Mike Litorus"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-05.jpg"
                                  alt=""
                                />
                              </a>
                              <a
                                title="Wilmer Deluna"
                                data-toggle="tooltip"
                                href="#"
                                className="avatar"
                              >
                                <img
                                  src="assets/img/profiles/avatar-11.jpg"
                                  alt=""
                                />
                              </a>
                              <span className="all-team">+2</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Description</label>
                            <textarea className="form-control"></textarea>
                          </div>
                          <div className="form-group">
                            <label>Upload Files</label>
                            <input className="form-control" type="file" />
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Edit Ticket Modal -->*/}

            {/*<!-- Delete Ticket Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_ticket"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Ticket</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary continue-btn"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="javascript:void(0);"
                            data-dismiss="modal"
                            className="btn btn-primary cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Delete Ticket Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Tickets));
