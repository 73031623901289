import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Datepicker from "react-datetime";
import moment from "moment";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";
import { toast } from "react-toastify";

function Resignation() {
  const [employees, setEmployees] = useState([]);
  const [resignations, setResignations] = useState([]);
  const [resignationIdToDelete, setResignationIdToDelete] = useState("");
  const [resignationData, setResignationData] = useState({
    employeeId: "",
    noticeDate: "",
    resignationDate: "",
    reason: "",
    departmentId: "",
  });

  const [resignationDataToUpdate, setResignationDataToUpdate] = useState({
    employeeName: "",
    employeeId: "",
    noticeDate: "",
    resignationDate: "",
    reason: "",
    departmentId: "",
  });

  useEffect(() => {
    getEmployees();
    getResignations();
    return () => {};
  }, []);

  const setEmployeeId = (userId) => {
    const employee = employees.find((employee) => employee.userId === userId);
    setResignationData({
      ...resignationData,
      employeeId: userId,
      departmentId: employee.jobInfo?.department?._id,
    });
  };

  const setResignationToUpdate = (resignation) => {
    const employee = employees.find(
      (employee) => employee.userId === resignation.employeeId
    );
    setResignationDataToUpdate({
      employeeName:
        employee?.personalInfo.name.first +
        " " +
        employee?.personalInfo.name.last,
      departmentId: resignation.departmentId,
      employeeId: resignation.employeeId,
      noticeDate: resignation.noticeDate,
      resignationDate: resignation.resignationDate,
      reason: resignation.reason,
    });
  };

  const getEmployees = () => {
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const date = new Date();
        setEmployees(result.employees);
        setResignationData({
          ...resignationData,
          noticeDate: moment(date).format("DD/MM/YYYY"),
          resignationDate: moment(date).format("DD/MM/YYYY"),
        });
      })
      .catch((err) => console.log(err));
  };

  const getResignations = () => {
    const token = "dummy token";
    fetch(`${URLS.backendResignation}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        setResignations(result.resignations);
        // const date = new Date();
        // setEmployees(result.employees);
      })
      .catch((err) => console.log(err));
  };

  const submitResignation = (e) => {
    e.preventDefault();

    const token = "dummy token";
    fetch(`${URLS.backendResignation}/save`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ resignationData }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          setResignations([...resignations, result.resignation]);
          window.$("#add_resignation").modal("toggle");
          toast("Resignation saved", {
            type: "success",
          });
        } else {
          if (result.exists) {
            toast("Employee has already resigned", {
              type: "error",
            });
            return;
          }
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to save resignation", {
          type: "error",
        });
      });
  };

  const editResignation = (e) => {
    e.preventDefault();

    const token = "dummy token";
    fetch(`${URLS.backendResignation}/edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ resignationToEdit: resignationDataToUpdate }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const foundIndex = resignations.findIndex(
            (resign) => resign.employeeId === result.resignation.employeeId
          );
          resignations[foundIndex] = result.resignation;
          setResignations([...resignations]);

          window.$("#edit_resignation").modal("toggle");
          toast("Resignation saved", {
            type: "success",
          });
        } else {
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to edit resignation", {
          type: "error",
        });
      });
  };

  const deleteResignation = () => {
    window.$("#delete_resignation").modal("toggle");
    const token = "dummy token";
    fetch(`${URLS.backendResignation}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ resignationId: resignationIdToDelete }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = resignations.filter(
            (resign) => resign._id !== resignationIdToDelete
          );
          setResignations([...removedDeleted]);

          toast("Resignation deleted", {
            type: "success",
          });
        } else {
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to delete resignation", {
          type: "error",
        });
      });
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Resignation</BreadcrumbItem>
                </Breadcrumb>
                {/* <h4 className="page-title">Employee</h4> */}
              </div>
              <div className="col-auto text-right float-right ml-auto m-b-30">
                <a
                  href="#"
                  className="btn add-btn"
                  data-toggle="modal"
                  data-target="#add_resignation"
                >
                  <i className="fa fa-plus"></i>Add Resignation
                </a>
              </div>
            </div>
            {/*<!-- /Page Title -->*/}

            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  {
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Resigning Employee </th>
                          <th>Department </th>
                          <th>Reason </th>
                          <th>Notice Date </th>
                          <th>Resignation Date </th>
                          <th className="text-right">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resignations.map((resignation, index) => {
                          const { employee, department } = resignation;
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <h2 className="table-avatar blue-link">
                                  <Link
                                    to={`profile/${employee.userId}`}
                                    className="avatar"
                                  >
                                    <img
                                      alt=""
                                      src="assets/img/profiles/avatar-02.jpg"
                                    />
                                  </Link>
                                  <Link to={`profile/${employee.userId}`}>
                                    {employee.personalInfo.name.first +
                                      " " +
                                      employee.personalInfo.name.last}
                                  </Link>
                                </h2>
                              </td>
                              <td>{department?.name}</td>
                              <td>{resignation.reason}</td>
                              <td>{resignation.noticeDate}</td>
                              <td>{resignation.resignationDate}</td>
                              <td className="text-right">
                                <div className="dropdown dropdown-action">
                                  <a
                                    href="#"
                                    className="action-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="material-icons">more_vert</i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#edit_resignation"
                                      onClick={() => {
                                        setResignationToUpdate(resignation);
                                      }}
                                    >
                                      <i className="fa fa-pencil m-r-5"></i>{" "}
                                      Edit
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#delete_resignation"
                                      onClick={() => {
                                        setResignationIdToDelete(
                                          resignation._id
                                        );
                                      }}
                                    >
                                      <i className="fa fa-trash-o m-r-5"></i>{" "}
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Page Content -->*/}

          {/*<!-- Add Resignation Modal -->*/}
          <div
            id="add_resignation"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Add Resignation</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => submitResignation(e)}>
                    <div className="form-group">
                      <label>
                        Resigning Employee
                        <span className="text-danger">*</span>
                      </label>
                      {employees && (
                        <select
                          className="form-control"
                          name="employeeId"
                          required
                          onChange={async (e) => {
                            setEmployeeId(e.target.value);
                          }}
                        >
                          <option value="">Select Employee</option>
                          {employees.map((employee, index) => {
                            return (
                              <option key={index} value={employee.userId}>
                                {employee.personalInfo.name.first +
                                  " " +
                                  employee.personalInfo.name.last}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Notice Date <span className="text-danger">*</span>
                      </label>
                      <div className="cal-icon">
                        <div classname="datePicker position-absolute">
                          {/*<i
                            className="fa fa-calendar fa-xs calendar-icon w-auto h-auto"
                            aria-hidden="true"
                          ></i>
													*/}
                          <Datepicker
                            value={resignationData.noticeDate}
                            required
                            onChange={(e) => {
                              setResignationData({
                                ...resignationData,
                                noticeDate: moment(e._d).format("DD/MM/YYYY"),
                              });
                            }}
                            closeOnSelect={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Resignation Date <span className="text-danger">*</span>
                      </label>
                      <div className="cal-icon">
                        <div classname="datePicker position-absolute">
                          {/*<i
                            className="fa fa-calendar fa-xs calendar-icon w-auto h-auto"
                            aria-hidden="true"
                          ></i>
													*/}
                          <Datepicker
                            value={resignationData.resignationDate}
                            required
                            onChange={(e) => {
                              setResignationData({
                                ...resignationData,
                                resignationDate: moment(e._d).format(
                                  "DD/MM/YYYY"
                                ),
                              });
                            }}
                            closeOnSelect={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="reason"
                        rows="4"
                        required
                        onChange={(e) => {
                          setResignationData({
                            ...resignationData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      >
                        {resignationData.reason}
                      </textarea>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Add Resignation Modal -->*/}

          {/*<!-- Edit Resignation Modal -->*/}
          <div
            id="edit_resignation"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Resignation</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => editResignation(e)}>
                    <div className="form-group">
                      <label>
                        Resigning Employee{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={resignationDataToUpdate.employeeName}
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Notice Date <span className="text-danger">*</span>
                      </label>
                      <div className="cal-icon">
                        <div classname="datePicker position-absolute">
                          <Datepicker
                            value={resignationDataToUpdate.noticeDate}
                            required
                            onChange={(e) => {
                              setResignationDataToUpdate({
                                ...resignationDataToUpdate,
                                noticeDate: moment(e._d).format("DD/MM/YYYY"),
                              });
                            }}
                            closeOnSelect={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Resignation Date <span className="text-danger">*</span>
                      </label>
                      <div className="cal-icon">
                        <div classname="datePicker position-absolute">
                          <Datepicker
                            value={resignationDataToUpdate.resignationDate}
                            required
                            onChange={(e) => {
                              setResignationDataToUpdate({
                                ...resignationDataToUpdate,
                                resignationDate: moment(e._d).format(
                                  "DD/MM/YYYY"
                                ),
                              });
                            }}
                            closeOnSelect={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows="4"
                        name="reason"
                        required
                        value={resignationDataToUpdate.reason}
                        onChange={(e) => {
                          setResignationDataToUpdate({
                            ...resignationData,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      >
                        {resignationDataToUpdate.reason}
                      </textarea>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Edit Resignation Modal -->*/}

          {/*<!-- Delete Resignation Modal -->*/}
          <div
            className="modal custom-modal fade"
            id="delete_resignation"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="form-header">
                    <h3>Delete Resignation</h3>
                    <p>Are you sure want to delete?</p>
                  </div>
                  <div className="modal-btn delete-action">
                    <div className="row">
                      <div className="col-6">
                        <a
                          href="javascript:void(0);"
                          className="btn btn-primary continue-btn"
                          onClick={() => {
                            deleteResignation();
                          }}
                        >
                          Delete
                        </a>
                      </div>
                      <div className="col-6">
                        <a
                          href="javascript:void(0);"
                          data-dismiss="modal"
                          className="btn btn-primary cancel-btn"
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<!-- /Delete Resignation Modal -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Resignation));
