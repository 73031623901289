import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch, Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { isEnterKey } from "../../utils";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { URLS } from "../urls";
import Prorated from "./Prorated";
import { toast } from "react-toastify";

function CreateSalary(props) {
  const date = new Date();
  const [activeTab, setActiveTab] = useState("1");
  const [normalEmployees, setNormalEmployees] = useState([]);
  const [proratedEmployees, setProratedEmployees] = useState([]);
  const [deductionSettings, setDeductionSettings] = useState([]);
  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: date.getMonth() + 1,
    payrollYear: date.getFullYear(),
  });
  const [settings, setSettings] = useState({
    sendMail: false,
  });
  const [btnText, setBtnText] = useState("Process");
  let isRendered = useRef(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getEmployees();

    return () => {};
  }, []);

  const getEmployees = async () => {
    try {
      const token = "dummy token";
      const res = await fetch(`${URLS.backendDeductionSettings}/enabled`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      const result = await res.json();
      setDeductionSettings(result.deductionSettings);

      const employeeRes = await fetch(`${URLS.backendPayroll}/get-employees`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });

      const employeesResult = await employeeRes.json();
      const { employees } = employeesResult;
      let normalEmployees = employees.filter(
        (employee) => employee?.jobInfo?.grade?.type === "normal"
      );
      normalEmployees = normalEmployees.map((employee) => {
        let deductionSum = 0;
        employee = {
          ...employee.personalInfo,
          ...employee.jobInfo,
          ...employee.jobInfo.grade.salary,
          userId: employee.userId,
          bankDetails: employee.bankDetails ?? {},
          gradeName: employee.jobInfo.grade.name,
        };
        const deductions = result.deductionSettings.map((deduction) => {
          let sum = 0;
          for (let key in deduction) {
            if (key !== "enabled" && deduction[key] === true) {
              sum += employee[key];
            }
          }
          const total = ((deduction.percentage / 100) * sum).toFixed(2);
          deductionSum += parseFloat(total);

          return {
            ...deduction,
            total: parseFloat(total),
          };
        });

        const {
          housing,
          basic,
          transport,
          medical,
          utility,
          paidLeave,
          gratuity,
          offshoreAllowances,
          transitAllowances,
          overTimeAllowances,
          bankDetails,
        } = employee;

        const grossPay =
          housing +
          basic +
          transport +
          medical +
          utility +
          paidLeave +
          gratuity;

        return {
          ...employee,

          ogGrossPay: grossPay,
          grossPay,
          otherDeductions: 0,
          bonuses: 0,
          totalDeduction: deductionSum,
          ogNetPay: grossPay - deductionSum,
          netPay: grossPay - deductionSum,
          deductions,
          debitAccountNo: "--",
          debitBankCode: "--",
          creditAccountNo: bankDetails?.accountNumber,
          creditBankCode: bankDetails?.bankCode,
        };
      });
      setNormalEmployees(normalEmployees);
      // calculateData(normalEmployees);
    } catch (err) {
      console.log(err);
    }
  };

  const calculateData = ({ e, employeeId }) => {
    if (isEnterKey(e)) {
      // const newValue = e.target.value ? parseFloat(e.target.value) : 0;
      const index = normalEmployees.findIndex(
        (employee) => employee.employeeId === employeeId
      );
      const employee = normalEmployees[index];
      const { ogGrossPay, bonuses, otherDeductions } = employee;

      let deductionSum = 0;
      const calculatedDeductions = deductionSettings.map((deduction) => {
        let sum = 0;
        for (let key in deduction) {
          if (key !== "enabled" && deduction[key] === true) {
            sum += employee[key];
          }
        }
        const total = ((deduction.percentage / 100) * sum).toFixed(2);
        deductionSum += parseFloat(total);

        return {
          ...deduction,
          total: parseFloat(total),
        };
      });

      const grossPay = ogGrossPay + bonuses;

      normalEmployees[index] = {
        ...employee,
        grossPay,
        deductions: calculatedDeductions,
        otherDeductions,
        totalDeduction: deductionSum + otherDeductions,
        netPay: grossPay - (deductionSum + otherDeductions),
      };

      setNormalEmployees([...normalEmployees]);
      e.target.blur();
    }
  };

  const setOtherDeductions = ({ e, employeeId }) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    const index = normalEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = normalEmployees[index];

    normalEmployees[index] = {
      ...employee,
      otherDeductions: newValue,
    };

    setNormalEmployees([...normalEmployees]);
  };

  const setBonuses = ({ e, employeeId }) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    const index = normalEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = normalEmployees[index];

    normalEmployees[index] = {
      ...employee,
      bonuses: newValue,
    };

    setNormalEmployees([...normalEmployees]);
  };

  const setRemark = ({ e, employeeId }) => {
    const index = normalEmployees.findIndex(
      (employee) => employee.employeeId === employeeId
    );
    const employee = normalEmployees[index];

    normalEmployees[index] = {
      ...employee,
      remark: e.target.value,
    };
    setNormalEmployees([...normalEmployees]);
  };

  const savePayroll = () => {
    setBtnText("Please wait...");
    let pE = proratedEmployees.map((employee) => {
      return {
        ...employee,
        payrollMonth: payrollDate.payrollMonth,
        payrollYear: payrollDate.payrollYear,
        debitAccountNo: props.settings.debitAccountNumber,
        debitBankCode: props.settings.debitBankCode,
        type: "prorated",
      };
    });

    let nE = normalEmployees.map((employee) => {
      return {
        ...employee,
        payrollMonth: payrollDate.payrollMonth,
        payrollYear: payrollDate.payrollYear,
        debitAccountNo: props.settings.debitAccountNumber,
        debitBankCode: props.settings.debitBankCode,
        type: "normal",
      };
    });

    fetch(`${URLS.backendPayroll}/save-payroll`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token dummy token`,
      },
      body: JSON.stringify({
        payroll: [...pE, ...nE],
        payrollMonth: payrollDate.payrollMonth,
        payrollYear: payrollDate.payrollYear,
        settings,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        if (result.success) {
          toast("payroll created", {
            type: "success",
          });
        } else {
          throw Error();
        }
      })
      .catch((err) => {
        toast("payroll deleted", {
          type: "error",
        });
      })
      .finally(() => {
        setBtnText("Process");
      });
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>Invex ERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-sm-12 col-12 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Process Payroll</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-sm-5 col-5"></div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- /Page Title -->*/}
            {/*<!-- Content Starts -->*/}
            <div className="mt-3">
              <div className="d-flex col-md-6 my-4">
                <div className="form-inline">
                  <label>Month</label>
                  <select
                    className="form-control mx-2"
                    value={payrollDate.payrollMonth}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: e.target.value,
                        payrollYear: payrollDate.payrollYear,
                      })
                    }
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <div className="form-inline ml-3">
                  <label>Year</label>
                  <input
                    type="number"
                    style={{ width: "100px" }}
                    className="form-control mx-2"
                    value={payrollDate.payrollYear}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: payrollDate.payrollMonth,
                        payrollYear: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Normal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Prorated
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab} className="pt-0">
                <TabPane tabId="1">
                  <Row>
                    <div className="col-md-12 w-100">
                      <div className="table-responsive">
                        <table className="table table-striped custom-table mb-0 datatable">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Name</th>
                              <th>Job Grade</th>
                              <th>Basic</th>
                              <th>Housing</th>
                              <th>Transport</th>
                              <th>Medical</th>
                              <th>Utility</th>
                              <th>Paid Leave</th>
                              <th>Gratuity</th>

                              <th>Bonuses</th>

                              <th>Gross Pay</th>

                              {deductionSettings.map((setting, index) => {
                                return (
                                  <th className="text-capitalize" key={index}>
                                    {setting.name}
                                  </th>
                                );
                              })}

                              <th>Other Deductions</th>
                              <th>Remark</th>
                              <th>Net Pay</th>
                            </tr>
                          </thead>
                          <tbody>
                            {normalEmployees.map((employee, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {employee.name.first +
                                      " " +
                                      employee.name.last}
                                  </td>
                                  <td>{employee.gradeName}</td>
                                  <td>{employee.basic}</td>
                                  <td>{employee.housing}</td>
                                  <td>{employee.transport}</td>
                                  <td>{employee.medical}</td>
                                  <td>{employee.utility}</td>
                                  <td>{employee.paidLeave}</td>
                                  <td>{employee.gratuity}</td>

                                  <td>
                                    <input
                                      value={employee.bonuses}
                                      onChange={(e) => {
                                        setBonuses({
                                          e,
                                          employeeId: employee.employeeId,
                                        });
                                      }}
                                      onKeyUp={(e) => {
                                        calculateData({
                                          e,
                                          employeeId: employee.employeeId,
                                        });
                                      }}
                                      className="form-control payroll-input"
                                    />
                                  </td>
                                  <td>{employee.grossPay}</td>

                                  {employee.deductions.map(
                                    (deduction, index) => {
                                      return (
                                        <td key={index}>{deduction.total}</td>
                                      );
                                    }
                                  )}

                                  <td>
                                    <input
                                      value={employee.otderDeductions}
                                      onChange={(e) => {
                                        setOtherDeductions({
                                          e,
                                          employeeId: employee.employeeId,
                                        });
                                      }}
                                      onKeyUp={(e) => {
                                        calculateData({
                                          e,
                                          employeeId: employee.employeeId,
                                        });
                                      }}
                                      className="form-control payroll-input"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      value={employee.remark ?? "..."}
                                      onClick={(e) => e.target.select()}
                                      onChange={(e) => {
                                        setRemark({
                                          e,
                                          employeeId: employee.employeeId,
                                        });
                                      }}
                                      className="form-control payroll-input"
                                    />
                                  </td>
                                  <td>{employee.netPay}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Prorated setProratedEmployees={setProratedEmployees} />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>

              <div className="mt-2">
                <div className="my-3">
                  <label>Send Payslip Mail</label>
                  <div
                    className="onoffswitch ml-0"
                    onClick={(e) => {
                      setSettings({
                        ...settings,
                        sendMail: !settings.sendMail,
                      });
                    }}
                  >
                    <input
                      type="checkbox"
                      name="onoffswitch"
                      className="onoffswitch-checkbox"
                      checked={settings.sendMail}
                    />
                    <label className="onoffswitch-label">
                      <span className="onoffswitch-inner"></span>
                      <span className="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
                <hr />
                <button class="btn btn-primary" onClick={savePayroll}>
                  {btnText}
                </button>
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
    settings: state.settingsReducer.settings,
    // deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateSalary));
