import React from "react";
import { connect } from "react-redux";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { withRouter, Route, Switch, Link } from "react-router-dom";
import moment from "moment";
import { Col, Form, Row } from "react-bootstrap";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import EditContractModal from "../contracts/EditContractModal";
import Project from "../projects/Project";
import { Helmet } from "react-helmet";
import { URLS } from "../urls";
import AllTypeTasks from "../tasks/AllTypeTasksClient";

class ProjectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: {
        contractName: "",
        contractNo: "",
        contractDescription: "",
        startDate: moment(new Date()).format("DD/MM/YYYY"),
        endDate: "",
        client: null,
        files: [],
      },
      projects: [],
      editProject: "",
      completedTaskCount: 0,
      inCompletedTaskCount: 0,
      underReviewedTaskCount: 0,
      projectTasks: [],
      completedTask: [],
      inCompletedTask: [],
      underReviewedTask: [],
      clientList: [],
      employeesList: [],
    };
  }

  editProject = (_id) => {
    this.setState({ editProject: _id });
  };

  getEditProjectDataFromId = (id) => {
    const { projects } = this.state;
    // return projects.find(item => id === item._id)
    return this.state.project;
  };
  addNewMember = (id) => {
    const { project } = this.state;
    if (project.team.find((m) => m._id === id)) {
      alert("Already a team member.");
      return;
    } else {
      project.team = project.team.map((m) => m._id);
      project.team.push(id);
      project.projectLeader = project.projectLeader.map((m) => m._id);

      let formData = new FormData();
      formData.append("project", JSON.stringify({ ...project }));

      const token = "dummy token";
      fetch(`${URLS.backendProjects}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            project: result.project,
          });
        });
    }
  };
  addNewLeader = (id) => {
    const { project } = this.state;
    if (project.projectLeader.find((m) => m._id === id)) {
      alert("Already a team leader.");
      return;
    } else {
      project.team = project.team.map((m) => m._id);
      project.projectLeader = project.projectLeader.map((m) => m._id);
      project.projectLeader.push(id);

      let formData = new FormData();
      formData.append("project", JSON.stringify({ ...project }));

      const token = "dummy token";
      fetch(`${URLS.backendProjects}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            project: result.project,
          });
        });
    }
  };

  // changeStatusOfTask = (task) => (event) => {
  // 	const token = 'dummy token';
  // 	const taskId = task._id;
  // 	// const { activeProjectId, tasks } = this.state;
  // 	task.status = task.status === 'COMPLETE' ? 'INCOMPLETE' : 'COMPLETE'
  // 	fetch(`${URLS.backendTasks}/update`, {
  // 		method: 'POST',
  // 		headers: {
  // 			Accept: 'application/json',
  // 			'Content-Type': 'application/json',
  // 			Authorization: `Token ${token}`,
  // 		},
  // 		body: JSON.stringify({ task: task })
  // 	}).then(res => res.json())
  // 		.then((result) => {
  // 			let { projectTasks, completedTask, inCompletedTask } = this.state
  // 			if (result.task.status === 'COMPLETE') {
  // 				completedTask.push(result.task)
  // 				console.log("inCompletedTask", inCompletedTask)
  // 				inCompletedTask = inCompletedTask.filter(item => {
  // 					console.log(item._id, result.task._id)
  // 					return item._id != result.task._id
  // 				})
  // 				console.log("inCompletedTask", inCompletedTask)
  // 			} else {
  // 				console.log("completedTask", completedTask)
  // 				completedTask = completedTask.filter(item => {
  // 					console.log(item._id, result.task._id)
  // 					return item._id != result.task._id
  // 				})
  // 				console.log("completedTask", completedTask)
  // 				inCompletedTask.push(result.task)
  // 			}

  // 			this.state.completedTask.forEach(projectTask => {
  // 				if (projectTask._id === taskId) {
  // 					projectTask.status = projectTask.status === 'COMPLETE' ? 'COMPLETE' : 'INCOMPLETE';
  // 				}
  // 			});
  // 			this.setState({
  // 				// tasks: tasks,
  // 				selectedTask: result.task,
  // 				completedTask,
  // 				inCompletedTask
  // 			});
  // 			console.log('....result......', result);
  // 		});
  // }

  // changeSelectedTask = (task) => (evt) => {
  // 	this.setState({
  // 		selectedTask: task
  // 	});
  // }

  componentWillMount() {
    const id = window.location.pathname.split("/")[3];
    const token = "dummy token";
    fetch(`${URLS.backendContracts}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ contract: result.contract });
      });

    fetch(`${URLS.backendProjects}/getProjectsByContractId/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          projects: result.projects,
        });
      });

    fetch(`${URLS.backendTasks}/getByContract/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          projectTasks: result.tasks,
        });
      });

    // fetch(`${URLS.backendTasks}/getByProjectId/${id}`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Token ${token}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((result) => {
    //     const tasks = result.tasks || [];
    //     const completedTask = tasks.filter((task) => {
    //       return task.status === "COMPLETE";
    //     });
    //     const completedTaskCount = completedTask.length;
    //     const inCompletedTask = tasks.filter((task) => {
    //       return task.status === "INCOMPLETE";
    //     });
    //     const inCompletedTaskCount = inCompletedTask.length;
    //     const underReviewedTask = tasks.filter((task) => {
    //       return task.status === "UNDER_REVIEW";
    //     });
    //     const underReviewedTaskCount = underReviewedTask.length;
    //     this.setState({
    //       completedTask,
    //       inCompletedTask,
    //       underReviewedTask,
    //       completedTaskCount,
    //       inCompletedTaskCount,
    //       underReviewedTaskCount,
    //       projectTasks: tasks,
    //     });
    //   });

    fetch(`${URLS.backendClients}/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ clientList: result.clients });
      });

    // fetch(`${URLS.backendEmployees}/get`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Token ${token}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((result) => {
    //     this.setState({ employeesList: result.employees });
    //   });
  }

  updateContractsList = (_contract) => {
    this.setState({
      contract: _contract,
    });
  };
  render() {
    const {
      contract,
      editContract,
      projectTasks,
      completedTaskCount,
      inCompletedTaskCount,
      underReviewedTaskCount,
      clientList,
      employeesList,
      projects,
    } = this.state;

    // const progress =
    //   completedTaskCount + inCompletedTaskCount + underReviewedTaskCount > 0
    //     ? (
    //         (completedTaskCount * 100) /
    //         (completedTaskCount + inCompletedTaskCount + underReviewedTaskCount)
    //       ).toFixed(2)
    //     : 0;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>Invex ERP</title>
        </Helmet>
        <div className="main-wrapper">
          <Topbar />
          <Sidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="row">
                <div className="col-sm-7 col-5">
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <Link to={`${URLS.contracts}`}>Contracts</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>
                      {contract.contractName}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
                <div className="col-sm-5 col-7 text-right m-b-30">
                  <a
                    href="Javascript:void(0);"
                    className="btn add-btn"
                    data-toggle="modal"
                    data-target="#edit_contract"
                  >
                    <i className="fa fa-plus"></i> Edit Contract
                  </a>
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              <div className="row">
                <div className="col-lg-8 col-xl-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="project-title">
                        <h5 className="card-title">{contract.contractName}</h5>
                        {/* <small className="block text-ellipsis m-b-15">
                          <span className="text-xs">
                            {inCompletedTaskCount + underReviewedTaskCount}
                          </span>{" "}
                          <span className="text-muted">open tasks, </span>
                          <span className="text-xs">
                            {completedTaskCount}
                          </span>{" "}
                          <span className="text-muted">tasks completed</span>
                        </small> */}
                      </div>
                      <span style={{ fontSize: "13px", opacity: 0.8 }}>
                        {contract.contractDescription}
                      </span>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title m-b-20">Uploaded files</h5>
                      <ul className="files-list">
                        {contract.files.length
                          ? contract.files.map((file) => {
                              return (
                                <li>
                                  <div className="files-cont">
                                    <div className="file-type">
                                      <span className="files-icon">
                                        <i className="fa fa-file-pdf-o"></i>
                                      </span>
                                    </div>
                                    <div className="files-info">
                                      <span className="file-name text-ellipsis">
                                        <a
                                          href={`${URLS.backendStatic}/${
                                            file.uniqueName +
                                            "." +
                                            file.extension
                                          }`}
                                          target="_blank"
                                        >
                                          {file.originalName +
                                            "." +
                                            file.extension}
                                        </a>
                                      </span>
                                      <div className="file-size">
                                        {file.size}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                  </div>

                  {/* <div className="project-task">
                    <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          href="#all_tasks"
                          data-toggle="tab"
                          aria-expanded="true"
                        >
                          All Tasks
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#pending_tasks"
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          Pending Tasks
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#under_reviewed_tasks"
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          Under Review Tasks
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#completed_tasks"
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          Completed Tasks
                        </a>
                      </li>
                    </ul>
                    <AllTypeTasks projectTasks={projectTasks} />
                  </div> */}
                </div>
                <div className="col-lg-4 col-xl-3">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title m-b-15">Contract details</h6>
                      <table className="table table-striped table-border">
                        <tbody>
                          <tr>
                            <td>Number:</td>
                            <td className="text-right">
                              {contract.contractNo}
                            </td>
                          </tr>
                          <tr>
                            <td>Created:</td>
                            <td className="text-right">{contract.startDate}</td>
                          </tr>
                          <tr>
                            <td>End Date:</td>
                            <td className="text-right">{contract.endDate}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-box tab-box col-lg-12">
                <div className="user-tabs">
                  <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item col-sm-3">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#myprojects"
                        >
                          My Projects
                        </a>
                      </li>
                      <li className="nav-item col-sm-3">
                        <a className="nav-link" data-toggle="tab" href="#tasks">
                          Tasks
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                {/* <div className="col-lg-12"> */}
                <div className="tab-content profile-tab-content">
                  {/*<!-- Projects Tab -->*/}
                  <div id="myprojects" className="tab-pane fade show active">
                    <div className="row">
                      {projects.length
                        ? projects.map((project) => {
                            let projectTeam = [];
                            let projectLeader = [];
                            if (project.team && project.team.length) {
                              projectTeam = project.team.map((employee) => {
                                let name = employee.personalInfo.name.first;
                                name += employee.personalInfo.name.middle
                                  ? " " + employee.personalInfo.name.middle
                                  : "";
                                name += employee.personalInfo.name.last
                                  ? " " + employee.personalInfo.name.last
                                  : "";
                                const id = employee._id;
                                const image = employee.emergencyInfo.image;
                                return {
                                  name: name,
                                  id: id,
                                  image: image,
                                };
                              });
                            }
                            if (
                              project.projectLeader &&
                              project.projectLeader.length
                            ) {
                              projectLeader = project.projectLeader.map(
                                (employee) => {
                                  let name = employee.personalInfo.name.first;
                                  name += employee.personalInfo.name.middle
                                    ? " " + employee.personalInfo.name.middle
                                    : "";
                                  name += employee.personalInfo.name.last
                                    ? " " + employee.personalInfo.name.last
                                    : "";
                                  const id = employee._id;
                                  const image = employee.emergencyInfo.image;
                                  return {
                                    name: name,
                                    id: id,
                                    image: image,
                                  };
                                }
                              );
                            }
                            return (
                              <Project
                                key={project._id}
                                project={project}
                                projectTeamList={projectTeam}
                                projectLeaderList={projectLeader}
                                onEdit={this.editProject}
                                canShowControls={false}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>

                  {/*<!-- /Projects Tab -->*/}

                  {/*<!-- Task Tab -->*/}
                  <div id="tasks" className="tab-pane fade">
                    <div className="project-task">
                      <ul className="nav nav-tabs nav-tabs-top nav-justified mb-0">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            href="#all_tasks"
                            data-toggle="tab"
                            aria-expanded="true"
                          >
                            All Tasks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#pending_tasks"
                            data-toggle="tab"
                            aria-expanded="false"
                          >
                            Pending Tasks
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            href="#completed_tasks"
                            data-toggle="tab"
                            aria-expanded="false"
                          >
                            Completed Tasks
                          </a>
                        </li>
                      </ul>
                      <AllTypeTasks projectTasks={projectTasks} />
                    </div>
                  </div>
                  {/*<!-- /Task Tab -->*/}
                </div>
                {/* </div> */}
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Assign User Modal -->*/}
            {/* <AddMemberModal
              team={employeesList.filter(
                (ar) => !project.projectLeader.find((rm) => rm._id === ar._id)
              )}
              projectTeam={project.team}
              projectLeader={project.projectLeader}
              addNewMember={this.addNewMember}
            /> */}
            {/*<!-- /Assign User Modal -->*/}

            {/*<!-- Assign Leader Modal -->*/}
            {/* <AddLeaderModal
              team={employeesList.filter(
                (ar) => !project.team.find((rm) => rm._id === ar._id)
              )}
              projectTeam={project.team}
              projectLeader={project.projectLeader}
              addNewLeader={this.addNewLeader}
            /> */}
            {/*<!-- /Assign Leader Modal -->*/}

            {/*<!-- Edit Contract Modal -->*/}
            <EditContractModal
              mode="edit"
              contract={contract}
              updateContractsList={this.updateContractsList}
              clientList={clientList}
            />
            {/*<!-- /Edit Project Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectView));
